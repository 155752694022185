import s from './styles.module.css';
import React, { useEffect, useMemo, useState } from 'react';
import { useTable } from 'react-table';
import ArrayIsEmpty from '../../UI/Array-is-empty/Array-is-empty';
import Pagination from '../../UI/Pagination/Pagination';
import { useNavigate } from 'react-router-dom';
import CustomSelector from '../../UI/CustomSelector/CustomSelector';

const LostItemsTable = ({
    t,
    language,
    initialData,
    page,
    setPage,
    handleChange,
    number,
    ordersData,
    refetch,
    openModal,
    setOpenModal,
}) => {
    const [tableData, setTableData] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        if (initialData && initialData.content) {
            setTableData(initialData.content);
        }
    }, [initialData]);
    const totalPages = initialData && initialData.totalPages;
    const columns = useMemo(
        () => [
            {
                Header: t('nameOrder'),
                accessor: 'name',
            },
            {
                Header: t('trackCode'),
                accessor: 'track_code',
                Cell: ({ value }) => value || 'N/A',
            },

            // {
            //     Header: 'Телефон филиала',
            //     accessor: 'customer.phone',
            //     Cell: ({ value }) => value || 'N/A',
            // },
            {
                Header: 'Город в котором должен быть товар',
                accessor: 'city_must.city_name',
            },
            {
                Header: 'Город в котором товар',
                accessor: 'city_fact.city_name',
            },
            // {
            //     Header: 'Тел номер филиала',
            //     accessor: 'box_price',
            // },
            {
                Header: t('taridId'),
                accessor: 'tariff_price',
                Cell: ({ value }) => (value ? `${value}$` : 'N/A'),
            },
            {
                Header: t('weight'),
                accessor: 'weight',
            },

            {
                Header: 'Дата когда приехал в город где потерян',
                accessor: 'created_at',
                Cell: ({ value }) => {
                    const date = new Date(value);
                    return date.toLocaleDateString();
                },
            },

            {
                Header: t('statusLan'),
                accessor: 'status',
                Cell: () => <p>Потерян</p>,
            },

            // {
            //     Header: t('plus-information'),
            //     accessor: 'plus-information',
            //     Cell: ({row}) => (
            //         <div className={s['button-div']}>
            //             <p className={s['button-table']}
            //                onClick={() => navigate(`/info-turkey`, { state: { rowData: row.original } })}
            //             >
            //                 {t('fullInfoButton')}
            //             </p>
            //         </div>
            //     ),
            // },
        ],
        [t, language],
    );
    const tableInstance = useTable({
        columns,
        data: tableData,
    });
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
    const options = [
        { value: 2, label: '2' },
        { value: 4, label: '4' },
        { value: 8, label: '8' },
    ];

    return (
        <div className={s['table-container']}>
            <div>
                <CustomSelector options={options} value={number} onChange={handleChange} />
            </div>
            <div className={s['table-order']}>
                {tableData.length === 0 ? (
                    <ArrayIsEmpty textOfEmpty={'Нету грузов'} />
                ) : (
                    <>
                        <table className={s['table']} {...getTableProps()}>
                            <thead>
                                {headerGroups.map((headerGroup) => {
                                    const { key: headerGroupKey, ...headerGroupRest } =
                                        headerGroup.getHeaderGroupProps();
                                    return (
                                        <tr key={headerGroupKey} {...headerGroupRest}>
                                            {headerGroup.headers.map((column) => {
                                                const { key: columnKey, ...columnRest } =
                                                    column.getHeaderProps();
                                                return (
                                                    <th
                                                        key={columnKey}
                                                        className={s['th-style']}
                                                        {...columnRest}
                                                    >
                                                        {column.render('Header')}
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.map((row) => {
                                    prepareRow(row);
                                    const { key: rowKey, ...rowRest } = row.getRowProps();
                                    return (
                                        <tr className={s['tbody']} key={rowKey} {...rowRest}>
                                            {row.cells.map((cell) => {
                                                const { key: cellKey, ...cellRest } =
                                                    cell.getCellProps();
                                                return (
                                                    <td
                                                        key={cellKey}
                                                        className={s['td-style']}
                                                        {...cellRest}
                                                    >
                                                        {cell.render('Cell')}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div className={s['mobile-view']}>
                            {rows.map((row) => {
                                prepareRow(row);
                                return (
                                    <div key={row.id} className={s['mobile-row']}>
                                        {row.cells.map((cell, cellIndex) => (
                                            <div
                                                key={cell.column.id}
                                                className={`${s['mobile-cell']} ${cellIndex === 3 ? s['with-line'] : ''}`}
                                            >
                                                <div className={s['mobile-header']}>
                                                    <span className={s['row-text-1']}>
                                                        {cellIndex === row.cells.length - 1
                                                            ? ''
                                                            : `${cell.column.Header}:`}
                                                    </span>
                                                </div>
                                                <div className={s['mobile-content']}>
                                                    <span className={s['row-text']}>
                                                        {cell.render('Cell')}
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
            </div>

            <div className={s['div-pages']}>
                {totalPages > 1 && (
                    <Pagination totalPages={totalPages} currentPage={page} setPage={setPage} />
                )}
            </div>
        </div>
    );
};
export default LostItemsTable;
