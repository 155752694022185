import './style.css';

const CreateBuyer = () => {
    return (
        <div>
            <h1>Создать Байера</h1>
            <form>
                <input type="text" placeholder="First Name" />
                <input type="text" placeholder="Last Name" />
                <input type="text" placeholder="Email" />
                <input type="password" placeholder="Password" />
                <button type="submit">Create Buyer</button>
            </form>
        </div>
    );
};
export default CreateBuyer;
