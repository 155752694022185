import React from 'react';

export const DeleteIcons = () => {
    return (
        <svg
            width="41"
            height="41"
            viewBox="0 0 41 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="0.5" y="0.848633" width="40" height="40" rx="20" fill="#F5F5F5" />
            <path
                d="M15.936 16.2847L20.4999 20.8486M20.4999 20.8486L25.0638 16.2847M20.4999 20.8486L15.936 25.4125M20.4999 20.8486L25.0638 25.4125"
                stroke="#6F6F70"
                stroke-width="2"
                stroke-linecap="square"
            />
        </svg>
    );
};
