import s from '../../pages/info-page/styles.module.css';

export function TurkeyIconPlane({ styleSVG }) {
    return (
        <svg className={s[`${styleSVG}`]} viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1128_6381)">
                <path d="M24.5 18.5628C24.5 19.2972 24.219 20.0016 23.719 20.5209C23.2189 21.0403 22.5406 21.332 21.8333 21.332H3.16667C2.45942 21.332 1.78115 21.0403 1.28105 20.5209C0.780952 20.0016 0.5 19.2972 0.5 18.5628V6.10126C0.5 5.36682 0.780952 4.66245 1.28105 4.14312C1.78115 3.62379 2.45942 3.33203 3.16667 3.33203H21.8333C22.5406 3.33203 23.2189 3.62379 23.719 4.14312C24.219 4.66245 24.5 5.36682 24.5 6.10126V18.5628Z" />
                <path
                    d="M11.165 15.9997C10.1042 15.9997 9.08674 15.5782 8.33659 14.8281C7.58645 14.0779 7.16502 13.0605 7.16502 11.9997C7.16502 10.9388 7.58645 9.92137 8.33659 9.17123C9.08674 8.42108 10.1042 7.99966 11.165 7.99966C12.0384 7.99966 12.845 8.28299 13.503 8.75832C13.0443 8.23641 12.4796 7.81833 11.8465 7.53196C11.2134 7.2456 10.5265 7.09754 9.83169 7.09766C8.5316 7.09766 7.28475 7.61412 6.36545 8.53342C5.44615 9.45272 4.92969 10.6996 4.92969 11.9997C4.9296 12.6435 5.05633 13.281 5.30264 13.8758C5.54895 14.4706 5.91001 15.0111 6.36521 15.4663C6.82042 15.9216 7.36084 16.2827 7.95562 16.5291C8.5504 16.7755 9.18789 16.9023 9.83169 16.9023C11.295 16.9023 12.605 16.2577 13.503 15.241C12.8235 15.7346 12.005 16.0002 11.165 15.9997ZM13.7737 12.153L15.4004 12.5277L15.547 14.1897L16.4057 12.759L18.0324 13.133L16.9364 11.8743L17.7944 10.443L16.259 11.0963L15.163 9.83699L15.3097 11.4997L13.7737 12.153Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_1128_6381">
                    <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
}
