import { Button, Form, Input, Select, Typography } from 'antd';
import { useFilias } from '../../../../api/common/common-hooks';
import React, { useContext, useEffect } from 'react';
import { UserInfoContext } from '../index';
import useCreateUser from '../../../../api/auth/auth-hooks';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../../assets/check.svg';
import s from '../styles.module.css';

export default function FilialStep() {
    const [form] = Form.useForm();
    const { mutate, isSuccess } = useCreateUser();
    const context = useContext(UserInfoContext);
    const { data } = useFilias(context.user.cityId);
    const navigate = useNavigate();

    const number = context.user.phone;

    useEffect(() => {
        if (data?.content?.length === 0) {
            mutate({
                username: '',
                phone: number,
                password: context.user.password,
                smsCode: '',
                cityId: context.user.cityId,
                filialId: 2,
                address: '',
                house: '',
            });
        }
    }, [data, mutate, number, context.user.password, context.user.cityId]);

    const onFinish = (values) => {
        mutate({
            username: '',
            phone: number,
            password: context.user.password,
            smsCode: '',
            cityId: context.user.cityId,
            filialId: values.filialId,
            address: '',
            house: '',
            fullName: values.fullName,
            lastName: values.lastName,
        });
    };

    useEffect(() => {
        if (isSuccess) {
            navigate('/client-main');
        }
    }, [isSuccess, navigate]);

    return (
        <>
            <Typography.Title style={{ color: 'white', fontWeight: '400' }} level={3}>
                Выберите филиал
            </Typography.Title>
            <Form form={form} onFinish={onFinish}>
                <Form.Item name="lastName">
                    <Input
                        style={{
                            height: '52px',
                            fontFamily: "'Roboto', sans-serif",
                            fontSize: '16px',
                            fontWeight: 400,
                            paddingLeft: '26px',
                            lineHeight: '24px',
                            textAlign: 'left',
                            borderRadius: '8px',
                        }}
                        placeholder="Введите фамилию"
                        className={s['register-input']}
                    />
                </Form.Item>
                <Form.Item name="fullName">
                    <Input
                        style={{
                            height: '52px',
                            fontFamily: "'Roboto', sans-serif",
                            fontSize: '16px',
                            fontWeight: 400,
                            paddingLeft: '26px',
                            lineHeight: '24px',
                            textAlign: 'left',
                            borderRadius: '8px',
                        }}
                        placeholder="Введите имя"
                        className={s['register-input']}
                    />
                </Form.Item>
                <Form.Item name="filialId">
                    <Select
                        style={{
                            width: '100%',
                            height: '52px',
                            fontFamily: "'Roboto', sans-serif",
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            textAlign: 'left',
                            borderRadius: '8px',
                        }}
                        placeholder="Выберите филиал"
                    >
                        {data &&
                            data.content?.map((item) => (
                                <Select.Option value={item.id} key={item.id}>
                                    {item.name}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>
                <div className={'logo-div'}>
                    <Logo width={'100%'} height={'100%'} />
                </div>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                            width: '100%',
                            height: '52px',
                            backgroundColor: 'white',
                            marginTop: '20px',
                        }}
                    >
                        <Typography.Text style={{ color: '#100E71', fontWeight: '500' }}>
                            Продолжить
                        </Typography.Text>
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
