import s from './styles.module.css';
import { useTable } from 'react-table';
import React, { useEffect, useMemo, useState } from 'react';
import ArrayIsEmpty from '../../UI/Array-is-empty/Array-is-empty';
import { useMutation, useQueryClient } from 'react-query';
import { axiosInstances } from '../../../api/axios-instances';
import CustomModal from '../../UI/Modal/Modal';
import Pagination from '../../UI/Pagination/Pagination';
import StatusColor from '../../UI/statusColor/statusColor';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

export const CreateOrderByUser = ({
    visible,
    onClose,
    inputData,
    onChange,
    onSave,
    translation,
    successModal,
    setError,
}) => {
    const [errors, setErrors] = useState({});
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const validateInputs = () => {
        const newErrors = {};
        if (!inputData.name) newErrors.name = 'Имя пустое';
        if (!inputData.track_code) newErrors.track_code = 'Трек код пустой';
        if (!inputData.price || isNaN(inputData.price)) newErrors.price = 'Цена должна быть';
        if (!inputData.amount || isNaN(inputData.amount))
            newErrors.amount = 'Количество должно быть цифрой';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSave = () => {
        if (validateInputs()) {
            onSave();
        } else {
            setShowErrorMessage(true);
            setTimeout(() => {
                setErrors({});
                setShowErrorMessage(false);
            }, 2000);
        }
    };

    return (
        <>
            <CustomModal visible={visible} onClose={onClose}>
                <div className={s['modal-div']}>
                    <p className={s['modal-title']}>{translation('createOrderByClient')}</p>
                    {setError != null ? <p className={s['modal-error']}>{setError}</p> : ''}
                    <div className={s['input-div']}>
                        <div className={s['input-container']}>
                            <input
                                className={`${s['input-modal']} ${errors.name ? s['input-error'] : ''}`}
                                name="name"
                                value={inputData.name}
                                onChange={onChange}
                            />
                            <label
                                className={`${s['floating-label']} ${inputData.name ? s.filled : ''}`}
                            >
                                {translation('writeOrderName')}
                            </label>
                        </div>
                        <div className={s['input-container']}>
                            <input
                                className={`${s['input-modal']} ${errors.track_code ? s['input-error'] : ''}`}
                                name="track_code"
                                value={inputData.track_code}
                                onChange={onChange}
                            />
                            <label
                                className={`${s['floating-label']} ${inputData.track_code ? s.filled : ''}`}
                            >
                                {translation('writeTrackCode')}
                            </label>
                        </div>
                        <div className={s['input-container']}>
                            <input
                                className={`${s['input-modal']} ${errors.price ? s['input-error'] : ''}`}
                                name="price"
                                type="number"
                                value={inputData.price || ''}
                                onChange={onChange}
                            />
                            <label
                                className={`${s['floating-label']} ${inputData.price ? s.filled : ''}`}
                            >
                                {translation('writePrice')}
                            </label>
                        </div>
                        <div className={s['input-container']}>
                            <input
                                className={`${s['input-modal']} ${errors.amount ? s['input-error'] : ''}`}
                                name="amount"
                                type="number"
                                value={inputData.amount || ''}
                                onChange={onChange}
                            />
                            <label
                                className={`${s['floating-label']} ${inputData.amount ? s.filled : ''}`}
                            >
                                {translation('writeAmount')}
                            </label>
                        </div>
                    </div>
                    {showErrorMessage && (
                        <p className={s['error-message']}>Заполните, пожалуйста, все поля.</p>
                    )}
                    <div className={s['modal-button']}>
                        <button className={s['button-modal']} onClick={onClose}>
                            {translation('otmena')}
                        </button>
                        <button className={s['button-modal']} onClick={handleSave}>
                            {translation('save')}
                        </button>
                    </div>
                </div>
            </CustomModal>
            <CustomModal visible={successModal} onClose={onClose}>
                <div className={s['done-modal']}>
                    <p className={s['userPlus']}>{translation('succesOderCreate')}</p>
                    <svg
                        width="101"
                        height="101"
                        viewBox="0 0 101 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M50.1357 100.842C43.3057 100.842 36.8678 99.5346 30.822 96.9202C24.8089 94.3058 19.4985 90.6947 14.8906 86.0869C10.2828 81.479 6.67169 76.1686 4.05731 70.1555C1.44293 64.1098 0.135742 57.6719 0.135742 50.8418C0.135742 44.0117 1.44293 37.5902 4.05731 31.5771C6.67169 25.5313 10.2665 20.2045 14.8416 15.5967C19.4495 10.9889 24.7599 7.37774 30.773 4.76337C36.8187 2.14899 43.2567 0.841797 50.0867 0.841797C56.9168 0.841797 63.3547 2.14899 69.4005 4.76337C75.4462 7.37774 80.773 10.9889 85.3808 15.5967C89.9887 20.2045 93.5998 25.5313 96.2142 31.5771C98.8286 37.5902 100.136 44.0117 100.136 50.8418C100.136 57.6719 98.8286 64.1098 96.2142 70.1555C93.5998 76.1686 89.9887 81.479 85.3808 86.0869C80.773 90.6947 75.4462 94.3058 69.4005 96.9202C63.3874 99.5346 56.9658 100.842 50.1357 100.842ZM44.6455 74.7634C45.4952 74.7634 46.2632 74.5673 46.9495 74.1751C47.6357 73.783 48.2403 73.1947 48.7632 72.4104L71.8024 36.0869C72.0965 35.5967 72.3743 35.0738 72.6357 34.5183C72.8972 33.9627 73.0279 33.4072 73.0279 32.8516C73.0279 31.7078 72.6031 30.8091 71.7534 30.1555C70.9037 29.4692 69.956 29.1261 68.9103 29.1261C67.4723 29.1261 66.2959 29.8777 65.3808 31.381L44.4495 65.0085L34.4985 52.1653C33.8776 51.3483 33.273 50.7928 32.6848 50.4987C32.1292 50.2045 31.492 50.0575 30.773 50.0575C29.6619 50.0575 28.7305 50.466 27.9789 51.283C27.2272 52.0673 26.8514 53.015 26.8514 54.1261C26.8514 54.6817 26.9495 55.2372 27.1455 55.7928C27.3743 56.3157 27.6684 56.8222 28.0279 57.3124L40.3318 72.4104C40.9854 73.2601 41.6553 73.8647 42.3416 74.2242C43.0279 74.5836 43.7959 74.7634 44.6455 74.7634Z"
                            fill="white"
                        />
                    </svg>
                    <button className={s['button-done']} onClick={onClose}>
                        {translation('continue')}
                    </button>
                </div>
            </CustomModal>
        </>
    );
};

const UserOrder = ({
    setOrderCreate,
    orderCreate,
    createOpenModal,
    setCreateOpenModal,
    tableOrder,
    page,
    setPage,
    language,
    refetch,
}) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const [tableData, setTableData] = useState([]);
    const [getImageOpenModal, setGetImageOpenModal] = useState(false);
    const [imageData, setImageData] = useState(null);
    const [successModal, setSuccessModal] = useState(false);
    const navigate = useNavigate();
    const [role, setRole] = useState(null);
    const [expandedRow, setExpandedRow] = useState(null);
    const [errorCatch, setErrorCatch] = useState(null);
    const getImage = async () => {
        const token = Cookies.get('token');
        if (!token) {
            throw new Error('Token is not available in localStorage');
        }
        const response = await axiosInstances.get('/amantrans/barcode/customer', {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            responseType: 'blob',
        });

        if (response.status !== 200) {
            throw new Error(`Error: ${response.status}`);
        }
        return response.data;
    };
    useEffect(() => {
        const token = Cookies.get('token');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                setRole(decodedToken.id);
            } catch (error) {
                setRole('');
            }
        } else {
            setRole('');
        }
    }, []);
    const openModalImage = async () => {
        try {
            const blob = await getImage();
            const url = URL.createObjectURL(blob);
            setImageData(url);
            setGetImageOpenModal(true);
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    };

    const closeModalImage = () => {
        setGetImageOpenModal(false);
        setImageData(null);
    };

    useEffect(() => {
        if (tableOrder && tableOrder.content) {
            setTableData(tableOrder.content);
        }
    }, [tableOrder]);
    const createOrderByUser = useMutation({
        mutationFn: async (user) => {
            const response = await axiosInstances.post(`/amantrans/orders/create`, user, {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}`,
                    'Content-Type': 'application/json',
                },
            });
            if (response.status !== 200) {
                throw new Error(`Error: ${response.status}`);
            }
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['customers']);
        },
        onError: (error) => {
            setErrorCatch(error.response.data);
        },
    });
    const handleSaveOrder = () => {
        const updateData = {
            name: orderCreate.name,
            track_code: orderCreate.track_code,
            price: parseFloat(orderCreate.price),
            amount: orderCreate.amount,
            customer_id: role,
        };
        createOrderByUser.mutate(updateData, {
            onSuccess: () => {
                setSuccessModal(true);
                setErrorCatch(null);
                setCreateOpenModal(false);
                setOrderCreate({
                    name: '',
                    track_code: '',
                    price: 0,
                    amount: '',
                    customer_id: 0,
                });
                refetch();
            },
            onError: (error) => {
                console.error('Error updating customer:', error);
            },
        });
    };

    const handleInputCreate = (e) => {
        const { name, value } = e.target;
        setOrderCreate((prevState) => ({ ...prevState, [name]: value }));
    };
    const handleCreateCloseModal = () => {
        setErrorCatch(null);
        setCreateOpenModal(false);
        setSuccessModal(false);
        setOrderCreate({
            name: '',
            track_code: '',
            price: 0,
            weight: '',
            amount: '',
            customer_id: 0,
        });
    };

    const totalPages = tableOrder.totalPages;

    const columns = useMemo(
        () => [
            // {
            //     Header: '',
            //     accessor: 'rowNumber',
            //     Cell: ({ row }) => row.index + 1,
            // },
            {
                Header: t('nameOrder'),
                accessor: 'name',
            },
            {
                Header: t('trackCode'),
                accessor: 'track_code',
            },
            {
                Header: t('price'),
                accessor: 'price',
                Cell: ({ value }) => (value ? `${value}$` : 'N/A'),
            },
            {
                Header: t('amount'),
                accessor: 'amount',
            },
            {
                Header: t('taridId'),
                accessor: 'tariff_price',
                Cell: ({ value }) => (value ? `${value}$` : 'N/A'),
            },
            {
                Header: t('weight'),
                accessor: 'weight',
            },
            {
                Header: t('created_at'),
                accessor: 'created_at',
                Cell: ({ value }) => {
                    const date = new Date(value);
                    return date.toLocaleDateString();
                },
            },
            {
                Header: t('boxPrice'),
                accessor: 'box_price',
            },
            {
                Header: t('delivery'),
                accessor: 'delivery_price',
            },
            {
                Header: t('allPrices'),
                accessor: 'total_price',
                Cell: ({ row }) => {
                    const boxPrice = parseFloat(row.original.box_price) || 0;
                    const deliveryPrice = parseFloat(row.original.delivery_price) || 0;
                    const usd = parseFloat(row.original.usd) || 0;
                    const totalPrice = ((boxPrice + deliveryPrice) * usd).toFixed(1);
                    return `(${boxPrice} + ${deliveryPrice}) * ${usd} = ${totalPrice} тг`;
                },
            },
            {
                Header: t('statusLan'),
                accessor: 'status',
                Cell: ({ value }) => (
                    <p className={s['status-color-name']} style={StatusColor(value.name_ru)}>
                        {language === 'ru' ? value.name_ru : value.name_kz}
                    </p>
                ),
            },
            {
                Header: t('plus-information'),
                accessor: 'plus-information',
                Cell: ({ row }) => {
                    const statusName = row.original.status.name;
                    return (
                        <div className={s['button-div']}>
                            <p
                                className={s['button-table']}
                                onClick={() =>
                                    navigate(`/info-china`, {
                                        state: { rowData: row.original },
                                    })
                                }
                            >
                                {t('fullInfoButton')}
                            </p>
                        </div>
                    );
                    // statusName !== 'DONE' ? (
                    //     statusName === 'FOR_ISSUE' ? (
                    //         <div className={s['button-div']}>
                    //             <p
                    //                 onClick={() => openModalImage()}
                    //                 className={s['button-table-take']}
                    //             >
                    //                 {t('take')}
                    //             </p>
                    //         </div>
                    //     ) : (

                    //     )
                    // ) : null;
                },
            },
        ],
        [language, t],
    );

    const tableInstance = useTable({
        columns,
        data: tableData,
    });

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

    return (
        <div>
            <CreateOrderByUser
                translation={t}
                visible={createOpenModal}
                onClose={handleCreateCloseModal}
                inputData={orderCreate}
                onChange={handleInputCreate}
                onSave={handleSaveOrder}
                successModal={successModal}
                setError={errorCatch}
            />
            <div className={s['table-order']}>
                {tableData.length === 0 ? (
                    <ArrayIsEmpty textOfEmpty={t('emptyPageOrder')} />
                ) : (
                    <>
                        <CustomModal visible={getImageOpenModal} onClose={closeModalImage}>
                            <div className={s['modal-div-barcode']}>
                                {imageData && (
                                    <img
                                        className={s['image-style-barcode']}
                                        src={`${imageData}`}
                                        alt="Barcode"
                                    />
                                )}
                                <button
                                    className={s['button-modal-barcode']}
                                    onClick={closeModalImage}
                                >
                                    {t('close')}
                                </button>
                            </div>
                        </CustomModal>
                        <table className={s['table']} {...getTableProps()}>
                            <thead>
                                {headerGroups.map((headerGroup) => {
                                    const { key: headerGroupKey, ...headerGroupRest } =
                                        headerGroup.getHeaderGroupProps();
                                    return (
                                        <tr key={headerGroupKey} {...headerGroupRest}>
                                            {headerGroup.headers.map((column) => {
                                                const { key: columnKey, ...columnRest } =
                                                    column.getHeaderProps();
                                                return (
                                                    <th
                                                        key={columnKey}
                                                        className={s['th-style']}
                                                        {...columnRest}
                                                    >
                                                        {column.render('Header')}
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.map((row) => {
                                    prepareRow(row);
                                    const { key: rowKey, ...rowRest } = row.getRowProps();
                                    return (
                                        <tr className={s['tbody']} key={rowKey} {...rowRest}>
                                            {row.cells.map((cell) => {
                                                const { key: cellKey, ...cellRest } =
                                                    cell.getCellProps();
                                                return (
                                                    <td
                                                        key={cellKey}
                                                        className={s['td-style']}
                                                        {...cellRest}
                                                    >
                                                        {cell.render('Cell')}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div className={s['mobile-view']}>
                            {rows.map((row) => {
                                prepareRow(row);
                                const isExpanded = expandedRow === row.id;
                                return (
                                    <div key={row.id} className={s['mobile-row']}>
                                        <div className={s['mobile-header']}>
                                            <div className={s['mobile-summary']}>
                                                <p>
                                                    <strong>{t('nameOrder')}:</strong>{' '}
                                                    {row.original.name}
                                                </p>
                                                <p>
                                                    <strong>{t('trackCode')}:</strong>{' '}
                                                    {row.original.track_code}
                                                </p>
                                                <p>
                                                    <strong>{t('price')}:</strong>{' '}
                                                    {row.original.price}$
                                                </p>
                                            </div>
                                            {isExpanded && (
                                                <div className={s['mobile-content']}>
                                                    {row.cells.map((cell, cellIndex) => {
                                                        if (
                                                            cell.column.id === 'name' ||
                                                            cell.column.id === 'track_code' ||
                                                            cell.column.id === 'price'
                                                        ) {
                                                            return null;
                                                        }
                                                        return (
                                                            <div
                                                                key={cell.column.id}
                                                                className={`${s['mobile-cell']} ${cellIndex === 3 ? s['with-line'] : ''}`}
                                                            >
                                                                <div className={s['mobile-header']}>
                                                                    <span
                                                                        className={s['row-text-1']}
                                                                    >
                                                                        {`${cell.column.Header}:`}
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    className={s['mobile-content']}
                                                                >
                                                                    <span className={s['row-text']}>
                                                                        {cell.render('Cell')}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                            <button
                                                className={s['toggle-button']}
                                                onClick={() =>
                                                    setExpandedRow(isExpanded ? null : row.id)
                                                }
                                            >
                                                {isExpanded ? (
                                                    <>
                                                        Скрыть
                                                        <svg
                                                            width="26"
                                                            height="26"
                                                            viewBox="0 0 26 26"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M12.9985 12.2536L9.38296 15.8691L7.96875 14.4549L12.9985 9.42519L18.0282 14.4549L16.614 15.8691L12.9985 12.2536Z"
                                                                fill="#898D94"
                                                            />
                                                        </svg>
                                                    </>
                                                ) : (
                                                    <>
                                                        Показать еще
                                                        <svg
                                                            width="26"
                                                            height="26"
                                                            viewBox="0 0 26 26"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M13.0015 13.7464L16.617 10.1309L18.0312 11.5451L13.0015 16.5748L7.97178 11.5451L9.38599 10.1309L13.0015 13.7464Z"
                                                                fill="#898D94"
                                                            />
                                                        </svg>
                                                    </>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
            </div>
            <div className={s['div-pages']}>
                {totalPages > 1 && (
                    <Pagination totalPages={totalPages} currentPage={page} setPage={setPage} />
                )}
            </div>
        </div>
    );
};

export default UserOrder;
