import s from './styles.module.css';
import { useMutation, useQuery } from 'react-query';
import React, { useEffect, useState } from 'react';
import { findByCityTurkey, getByDateTurkey } from '../../api/turkey-order-api/turkey-order-api';
import { jwtDecode } from 'jwt-decode';
import { useCities } from '../../api/user-list-api/user-list-api';
import { format } from 'date-fns';
import Cookies from 'js-cookie';
import LostItemsOrder from './lost-items-order/lost-items-order';
import { getAllAdmins, getLostItem } from '../../api/lost-items-api/lost-items-api';

const LostItems = ({ t, language }) => {
    const [number, setNumber] = useState(50);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState('');
    const [searchResults, setSearchResults] = useState(null);
    const [statusData, setStatusData] = useState(null);
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [getDate, setGetDate] = useState(null);
    const [role, setRole] = useState(null);
    const { data: citiesData } = useCities();
    const [city, setCity] = useState(null);
    const token = Cookies.get('token');
    const [selectedCity, setSelectedCity] = useState(null);
    const [getAdminList, setGetAdminList] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [roleCityId, setRoleCityId] = useState(null);
    useEffect(() => {
        if (token && typeof token === 'string') {
            try {
                const decodedToken = jwtDecode(token);
                console.log(decodedToken);
                setRole(decodedToken.role);
                setRoleCityId(decodedToken.cityId);
            } catch (error) {
                console.error('Ошибка декодирования токена', error);
                setRole('');
            }
        } else {
            setRole('');
        }
    }, [token]);
    const [adminGetCityId, setAdminGetCityId] = useState({ city: '' });
    const [searchParams, setSearchParams] = useState({
        city: `${role === 'RoleAdmin' ? roleCityId : ''}`,
        page: page,
        number: number,
    });
    useEffect(() => {
        if (role === 'RoleAdmin' && roleCityId) {
            setSearchParams((prev) => ({
                ...prev,
                city: roleCityId,
            }));
        }
    }, [role, roleCityId]);
    console.log(searchParams, 7978787787878787);
    const { data: initialData, refetch } = useQuery(['orders', searchParams], getLostItem, {
        keepPreviousData: true,
    });
    const { data: getAdmins } = useQuery(['orders', adminGetCityId], getAllAdmins, {
        keepPreviousData: true,
    });
    const mutationCity = useMutation(findByCityTurkey, {
        onSuccess: (data) => {
            setCity(data);
        },
        onError: (error) => {
            console.error('Error fetching user:', error);
        },
    });
    const mutationDate = useMutation(getByDateTurkey, {
        onSuccess: (data) => {
            setGetDate(data);
        },
        onError: (error) => {
            console.error('Error fetching user:', error);
        },
    });
    const handleChange = (event) => {
        const selectedNumber = parseInt(event.target.value);
        setNumber(selectedNumber);
        setPage(0);
        if (selectedCity) {
            mutationCity.mutate({
                city: selectedCity,
                number: parseInt(event.target.value),
                page: page,
            });
        } else if (startDate && endDate) {
            mutationDate.mutate({
                start: startDate,
                end: endDate,
                PerPage: parseInt(event.target.value),
                page: page,
            });
        } else if (selectedCity) {
            mutationCity.mutate({
                city: selectedCity,
                number: parseInt(event.target.value),
                page: page,
            });
        } else {
            refetch();
        }
    };
    const handlePageChange = (newPage) => {
        setPage(newPage);

        if (selectedCity) {
            mutationCity.mutate({ city: selectedCity, number: number, page: newPage });
        } else if (selectedCity) {
            mutationCity.mutate({ city: selectedCity, number: number, page: newPage });
        } else if (startDate && endDate) {
            mutationDate.mutate({ start: startDate, end: endDate, PerPage: number, page: newPage });
        } else {
            refetch();
        }
    };

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
        if (event.target.value.trim() !== '') {
            setIsSearchActive(true);
        } else {
            setIsSearchActive(false);
        }
    };
    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start ? format(start, 'yyyy-MM-dd') : null);
        setEndDate(end ? format(end, 'yyyy-MM-dd') : null);
    };
    const currentEndDate = endDate || format(new Date(), 'yyyy-MM-dd');
    const handleGetByDate = () => {
        setSearchParams({
            dateFrom: startDate,
            dateTo: currentEndDate,
            forSearch: '',
            city: '',
            adminsId: '',
        });
    };
    const handleChangeCity = (value) => {
        setSelectedCity(value);
        setSearchParams({
            dateFrom: startDate,
            dateTo: currentEndDate,
            forSearch: '',
            city: value,
            adminsId: '',
        });
        setAdminGetCityId({ city: value });
    };
    const handleChangeByAdmins = (value) => {
        setSearchParams({
            dateFrom: startDate,
            dateTo: currentEndDate,
            forSearch: '',
            city: '',
            adminsId: value,
        });
    };

    const handleSearchClick = () => {
        setSearchParams({ dateFrom: '', dateTo: '', forSearch: search, city: '' });
        refetch();
    };

    const handleShowAllClick = () => {
        setGetAdminList(null);
        setAdminGetCityId({ city: '' });
        setStartDate(null);
        setEndDate(null);
        setStatusData('');
        setCity(null);
        setGetDate(null);
        setSearch('');
        setIsSearchActive(false);
        setSearchResults(null);
        setSearchParams({ dateFrom: '', dateTo: '', forSearch: '', city: '' });
    };
    useEffect(() => {
        if (getAdmins && getAdmins.content) {
            setGetAdminList(getAdmins.content);
        }
    }, [initialData]);
    const tableData = getDate || city || statusData || searchResults || initialData;

    return (
        <div className={s['container']}>
            <div className={s['content']}>
                <div className={s['title']}>
                    <h1 className={s['title-text']}>{t('lostItems')}</h1>
                </div>
                {/*<div className={s['input']}>*/}
                {/*    <input*/}
                {/*        className={s['input-text']}*/}
                {/*        type="text"*/}
                {/*        placeholder={t('searchPlaceholder')}*/}
                {/*        value={search}*/}
                {/*        onChange={handleSearchChange}*/}
                {/*    />*/}
                {/*    <div className={s['button']}>*/}
                {/*        <button*/}
                {/*            className={s['button-text']}*/}
                {/*            onClick={handleSearchClick}*/}
                {/*            disabled={!isSearchActive}*/}
                {/*        >*/}
                {/*            {t('findOrder')}*/}
                {/*        </button>*/}
                {/*        <button className={s['button-text']} onClick={() => handleShowAllClick()}>*/}
                {/*            {t('showAll')}*/}
                {/*        </button>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*{role === 'RoleLeadAdmin' ? (*/}
                {/*    <div className={s['selecter-div']}>*/}
                {/*        <p className={s['style-text']}>{t('searchByCity')} </p>*/}
                {/*        <Select*/}
                {/*            onChange={handleChangeCity}*/}
                {/*            placeholder={`${t('takeCity')}`}*/}
                {/*            style={{ width: '200px', height: '25px', border: 'none', color: 'red' }}*/}
                {/*        >*/}
                {/*            {citiesData?.map((item) => (*/}
                {/*                <Option value={item.id} key={item.id}>*/}
                {/*                    {item.name}*/}
                {/*                </Option>*/}
                {/*            ))}*/}
                {/*        </Select>*/}
                {/*    </div>*/}
                {/*) : (*/}
                {/*    ''*/}
                {/*)}*/}
                {/*{role === 'RoleLeadAdmin' ? (*/}
                {/*    <div className={s['selecter-div']}>*/}
                {/*        <p className={s['style-text']}>Поиск по админу</p>*/}
                {/*        <Select*/}
                {/*            onChange={handleChangeByAdmins}*/}
                {/*            placeholder={`${t('Выберите админа')}`}*/}
                {/*            style={{ width: '200px', height: '25px', border: 'none', color: 'red' }}*/}
                {/*        >*/}
                {/*            {getAdminList?.map((item) => (*/}
                {/*                <Option value={item.id} key={item.id}>*/}
                {/*                    {item.name} {item.lastname}*/}
                {/*                </Option>*/}
                {/*            ))}*/}
                {/*        </Select>*/}
                {/*    </div>*/}
                {/*) : (*/}
                {/*    ''*/}
                {/*)}*/}
                {/*<div className={s['date-picker-container']}>*/}
                {/*    <p className={s['style-text']}>{t('searchByDate')}</p>*/}

                {/*    <DatePicker*/}
                {/*        className={s['react-datepicker']}*/}
                {/*        selected={startDate ? parseISO(startDate) : null}*/}
                {/*        onChange={handleDateChange}*/}
                {/*        startDate={startDate ? parseISO(startDate) : null}*/}
                {/*        endDate={endDate ? parseISO(endDate) : null}*/}
                {/*        selectsRange*/}
                {/*        locale="ru"*/}
                {/*        dateFormat="yyyy-MM-dd"*/}
                {/*        placeholderText={t('selectDateRange')}*/}
                {/*    />*/}
                {/*    <button onClick={() => handleGetByDate()} className={s['button-filter']}>*/}
                {/*        {t('find')}*/}
                {/*    </button>*/}
                {/*</div>*/}
                <div className={s['turkey-div']}>
                    <LostItemsOrder
                        t={t}
                        language={language}
                        initialData={tableData}
                        page={page}
                        setPage={handlePageChange}
                        handleChange={handleChange}
                        number={number}
                        refetch={refetch}
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                    />
                </div>
            </div>
        </div>
    );
};

export default LostItems;
