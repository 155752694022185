import React, { useEffect, useState } from 'react';
import useCities from '../../api/common/common-hooks';
import { counts } from '../deleted-users';
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'antd';
import { Select } from 'antd';
import { Option } from 'antd/es/mentions';
import { format } from 'date-fns';
import { getDeletedCargo, activateOrder } from '../../api/cargo-moderator-api/cargo-moderator-api';
import s from './styles.module.css';

export const columns = [
    'nameOrder',
    'idCode',
    'trackCode',
    'price',
    'amount',
    'taridId',
    'weight',
    'deleted_at',
    'functions',
];

const DeletedCargoPage = () => {
    const { RangePicker } = DatePicker;

    const { t } = useTranslation();
    const { data: cities } = useCities();

    const [selectedCountry, setSelectedCountry] = useState('china');
    const [deletedCargoList, setDeletedCargoList] = useState([]);

    const [filter, setFilter] = useState({
        start_date: '2021-01-01',
        end_date: format(new Date(), 'yyyy-MM-dd'),
        count: 100,
        forSearch: '',
    });

    const [errorMessage, setErrorMessage] = useState('');

    const handleRecoverCargo = (order) => {
        activateOrder(order.id)
            .then(() => updateDataFromServer(filter))
            .catch(() => setErrorMessage('Произошла ошибка, попробуйте позже'));
    };

    const handleFilterChange = (name, value) => {
        setErrorMessage('');
        setFilter((prev) => ({ ...prev, [name]: value }));
    };

    const handleDateRangeChecked = (range) => {
        setErrorMessage('');
        if (range[0] && range[1]) {
            setFilter((prev) => ({
                ...prev,
                start_date: format(new Date(range[0]), 'yyyy-MM-dd'),
                end_date: format(new Date(range[1]), 'yyyy-MM-dd'),
            }));
        }
    };

    const updateDataFromServer = (filter) => {
        getDeletedCargo({
            start: filter.start_date,
            end: filter.end_date,
            cityId: filter.cityId,
            forSearch: filter.forSearch,
            page: 0,
            number: filter.count,
            sort: 'ASC',
        }).then((data) => setDeletedCargoList(data.content));
    };

    useEffect(() => {
        updateDataFromServer(filter);
    }, [filter, selectedCountry]);

    return (
        <div className={s['container']}>
            <h1 className={s['title']}>{t('deletedCargo')}</h1>
            <div className={s['tabs']}>
                <span
                    className={s[selectedCountry === 'turkey' ? 'selectedTab' : 'tab']}
                    onClick={() => setSelectedCountry('turkey')}
                >
                    {t('ordersFromTurkey')}
                </span>
                <span
                    className={s[selectedCountry === 'china' ? 'selectedTab' : 'tab']}
                    onClick={() => setSelectedCountry('china')}
                >
                    {t('china')}
                </span>
            </div>
            <input
                className={s['input-style']}
                placeholder={t('searchPlaceholder')}
                value={filter.forSearch}
                onChange={(e) => handleFilterChange('forSearch', e.target.value)}
            />
            <div className={s['filter-wrap']}>
                <Select
                    onChange={(e) => handleFilterChange('cityId', e)}
                    placeholder={t('selectCity')}
                    size={'large'}
                >
                    {cities?.map((item) => (
                        <Option value={item.id} key={item.id}>
                            {item.name}
                        </Option>
                    ))}
                </Select>
                <RangePicker
                    onCalendarChange={handleDateRangeChecked}
                    size={'large'}
                    placeholder={[t('from'), t('to')]}
                    className={s['fullWidth']}
                />
                <Select
                    onChange={(e) => handleFilterChange('count', e)}
                    placeholder={t('selectCount')}
                    size={'large'}
                >
                    {counts?.map((item) => (
                        <Option value={item.value} key={item.value}>
                            {item.name}
                        </Option>
                    ))}
                </Select>
            </div>
            {errorMessage && <p className={s['error']}>{errorMessage}</p>}
            <div className={s['table']}>
                <div className={s['table-columns-wrap']}>
                    {columns.map((column, index) => (
                        <span key={index} className={s['table-cell']}>
                            {t(column)}
                        </span>
                    ))}
                </div>
                <div className={s['table-data']}>
                    {deletedCargoList.map((row, index) => (
                        <div key={index} className={s['table-columns-wrap']}>
                            <span className={s['table-cell']}>{row.name}</span>
                            <span className={s['table-cell']}>{row.customer?.id_code}</span>
                            <span className={s['table-cell']}>{row.track_code}</span>
                            <span className={s['table-cell']}>{row.price}</span>
                            <span className={s['table-cell']}>{row.amount}</span>
                            <span className={s['table-cell']}>{row.tariff_price}</span>
                            <span className={s['table-cell']}>{row.weight}</span>
                            <span className={s['table-cell']}>{row.deleted_at}</span>
                            <span className={s['table-cell']}>
                                <button
                                    className={s['primary-button']}
                                    onClick={() => handleRecoverCargo(row)}
                                >
                                    {t('recover')}
                                </button>
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
export default DeletedCargoPage;
