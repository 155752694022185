import React, { useEffect, useState } from 'react';
import useCities from '../../api/common/common-hooks';
import { useTranslation } from 'react-i18next';
import { getDeletedUsers, changeUserActiveStatus } from '../../api/customers/customers-api';
import { DatePicker } from 'antd';
import { Select } from 'antd';
import { Option } from 'antd/es/mentions';
import { format } from 'date-fns';

import s from './styles.module.css';

export const columns = [
    'idCode',
    'fullNameWithLastName',
    'email',
    'phone',
    'city',
    'deleted_at',
    'functions',
];

export const counts = [
    { name: 'Показывать по 10', value: 10 },
    { name: 'Показывать по 50', value: 50 },
    { name: 'Показывать по 100', value: 100 },
];

const DeletedCargoPage = () => {
    const { RangePicker } = DatePicker;

    const { t } = useTranslation();
    const { data: cities } = useCities();

    const [searchInput, setSearchInput] = useState('');
    const [deletedUsersList, setDeletedUsersList] = useState([]);
    const [filter, setFilter] = useState({
        start_date: '2021-01-01',
        end_date: format(new Date(), 'yyyy-MM-dd'),
        count: 100,
    });

    const [errorMessage, setErrorMessage] = useState('');

    const handleRecoverCargo = (user) => {
        changeUserActiveStatus(user.id)
            .then(() => updateDataFromServer(filter))
            .catch(() => setErrorMessage('Произошла ошибка, попробуйте позже'));
    };

    const handleFilterChange = (name, value) => {
        setErrorMessage('');
        setFilter((prev) => ({ ...prev, [name]: value }));
    };

    const handleDateRangeChecked = (range) => {
        setErrorMessage('');
        if (range[0] && range[1]) {
            setFilter((prev) => ({
                ...prev,
                start_date: format(new Date(range[0]), 'yyyy-MM-dd'),
                end_date: format(new Date(range[1]), 'yyyy-MM-dd'),
            }));
        }
    };

    const updateDataFromServer = (filter) => {
        getDeletedUsers({
            start: filter.start_date,
            end: filter.end_date,
            cityId: filter.cityId,
            page: 0,
            number: filter.count,
            sort: 'ASC',
        })
            .then((data) => setDeletedUsersList(data.content))
            .catch(() => setErrorMessage('Произошла ошибка, попробуйте позже'));
    };

    useEffect(() => {
        updateDataFromServer(filter);
    }, [filter]);

    return (
        <div className={s['container']}>
            <h1 className={s['title']}>{t('deletedUsers')}</h1>
            <input
                className={s['input-style']}
                placeholder={t('searchPersonPlaceholder')}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
            />
            <div className={s['filter-wrap']}>
                <Select
                    onChange={(e) => handleFilterChange('cityId', e)}
                    placeholder={t('selectCity')}
                    size={'large'}
                >
                    {cities?.map((item) => (
                        <Option value={item.id} key={item.id}>
                            {item.name}
                        </Option>
                    ))}
                </Select>
                <RangePicker
                    onCalendarChange={handleDateRangeChecked}
                    size={'large'}
                    placeholder={[t('from'), t('to')]}
                    className={s['fullWidth']}
                />
                <Select
                    onChange={(e) => handleFilterChange('count', e)}
                    placeholder={t('selectCount')}
                    size={'large'}
                >
                    {counts?.map((item) => (
                        <Option value={item.value} key={item.value}>
                            {item.name}
                        </Option>
                    ))}
                </Select>
            </div>

            {errorMessage && <p className={s['error']}>{errorMessage}</p>}

            <div className={s['table']}>
                <div className={s['table-columns-wrap']}>
                    {columns.map((column, index) => (
                        <span key={index} className={s['table-cell']}>
                            {t(column)}
                        </span>
                    ))}
                </div>
                <div className={s['table-data']}>
                    {deletedUsersList.map((row, index) => (
                        <div key={index} className={s['table-columns-wrap']}>
                            <span className={s['table-cell']}>{row.id}</span>
                            <span className={s['table-cell']}>{row.fullName}</span>
                            <span className={s['table-cell']}>{row.email}</span>
                            <span className={s['table-cell']}>{row.phone}</span>
                            <span className={s['table-cell']}>{row.customerCityName}</span>
                            <span className={s['table-cell']}>{row.deletedDate}</span>
                            <span className={s['table-cell']}>
                                <button
                                    className={s['primary-button']}
                                    onClick={() => handleRecoverCargo(row)}
                                >
                                    {t('recover')}
                                </button>
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
export default DeletedCargoPage;
