import React, { useEffect, useState } from 'react';
import {
    ClockImg,
    MailIcon,
    NewLogo,
    PhoneIcon,
    StarIcon,
    TargetImg,
    USDIcon,
    WeightIcon,
    WhatsAppIconBlue,
} from '../../assets';
import useCities from '../../api/common/common-hooks';
import { useTranslation } from 'react-i18next';
import { Option } from 'antd/es/mentions';

import { Link, useNavigate } from 'react-router-dom';
import { feedbackList } from './data';
import { getCurrencies, getTariffByCity } from '../../api/common/common';
import { Carousel, notification, Select } from 'antd';
import { useLocalstorage } from '../../hooks/use-localstorage';
import StatusColor from '../UI/statusColor/statusColor';
import i18n from '../../i18n';
import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
import qr from '../../assets/qr-link.svg';
import s from './styles.module.css';
import { searchTurkeyOrderByTrackcode } from '../../api/turkey-order-api/turkey-order-api';
import CustomModal from '../UI/Modal/Modal';

const LandingPage = ({ isMobile, lang }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { data: cities } = useCities();
    const [language, setLanguage] = useLocalstorage('language', 'kz');
    const [usdCurrency, setUsdCurrency] = useState(0);
    const [selectedCityId, setSelectedCityId] = useState(1);
    const [selectedWeight, setSelectedWeight] = useState(100);
    const [priceForCity, setPriceForCity] = useState(10);
    const [totalSum, setTotalSum] = useState(0);
    const minWeight = 100;
    const maxWeight = 1000;
    const [trackCode, setTrackCode] = useState('');
    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
    const [trackData, setTrackData] = useState([]);

    const [api, contextHolder] = notification.useNotification();

    const handleSelectCity = (city) => {
        setSelectedCityId(city);
    };

    const handleSliderChange = (e) => {
        setSelectedWeight(e.target.value);
    };

    const getBackgroundColor = () => {
        return {
            background: `linear-gradient(to right, #100e71 ${((selectedWeight - minWeight) * 100) / (maxWeight - minWeight)}%, #FFF 0)`,
        };
    };

    const handleSelectRu = () => {
        i18n.changeLanguage('ru');
        setLanguage('ru');
    };

    const handleSelectKz = () => {
        i18n.changeLanguage('kz');
        setLanguage('kz');
    };
    const handleSelectCh = () => {
        i18n.changeLanguage('ch');
        setLanguage('ch');
    };

    const showErrorNotification = () => {
        api.error({
            message: 'Произошла ошибка, попробуйте позже',
        });
    };

    const handleSearchClick = () => {
        searchTurkeyOrderByTrackcode(trackCode)
            .then((res) => {
                setTrackData(res);
                setIsSearchModalOpen(true);
            })
            .catch(() => showErrorNotification());
    };

    useEffect(() => {
        getTariffByCity(selectedCityId).then((res) => setPriceForCity(res));
    }, [selectedCityId]);

    useEffect(() => {
        setTotalSum((selectedWeight * priceForCity * usdCurrency) / 1000 + 0.2 * usdCurrency);
    }, [selectedWeight, priceForCity, usdCurrency]);

    useEffect(() => {
        getCurrencies().then((currency) => setUsdCurrency(currency));
    }, []);

    return (
        <div className={s['container']}>
            {contextHolder}
            <div className={s['header']}>
                <div className={s['row-wrap']}>
                    <img src={NewLogo} alt="" />
                    <div className={s['row-wrap-header']}>
                        <img src={PhoneIcon} alt="" />
                        <a href="tel:+77070491088" style={{ color: '#fff' }}>
                            +7 (707) 049-10-88
                        </a>
                    </div>
                    <div className={s['row-wrap-header']}>
                        <img src={MailIcon} alt="MailIcon" />
                        <a href="mailto:amantrans.kz@gmail.com" style={{ color: '#fff' }}>
                            amantrans.kz@gmail.com
                        </a>
                    </div>
                </div>
                <div className={s['row-wrap']}>
                    <div className={s['row-wrap-header']}>
                        <img src={USDIcon} alt="" />
                        <span> USD {usdCurrency} KZT</span>
                    </div>
                    <div className={s['row-wrap3']}>
                        <span
                            className={s[language === 'kz' ? 'active-lng' : 'passive-lng']}
                            onClick={handleSelectKz}
                        >
                            Қаз
                        </span>
                        <span className={s['circle']} />
                        <span
                            className={s[language === 'ru' ? 'active-lng' : 'passive-lng']}
                            onClick={handleSelectRu}
                        >
                            Рус
                        </span>
                        <span className={s['circle']} />
                        <span
                            className={s[language === 'ch' ? 'active-lng' : 'passive-lng']}
                            onClick={handleSelectCh}
                        >
                            中文
                        </span>
                    </div>
                </div>
            </div>
            <div className={s['content']}>
                <div className={s['card1']}>
                    <div className={s['first-info']}>
                        <p className={s['header-text']}>{t('landingHeader')}</p>
                        <p className={s['header-text']}>{t('landingHeader2')}</p>
                        <p className={s['header-text']}>{t('landingHeader3')}</p>
                        <div className={s['hint-wrap']}>
                            <p className={s['hint-text']}>{t('landingSubheader')}</p>
                            <p className={s['hint-text']}>{t('landingSubheader2')}</p>
                        </div>
                        <div className={s['row-wrap']}>
                            <button
                                className={s['primary-button']}
                                onClick={() => navigate('/auth')}
                            >
                                {t('enter')}
                            </button>
                            <button
                                className={s['secondary-button']}
                                onClick={() => navigate('/register')}
                            >
                                {t('authorized')}
                            </button>
                        </div>
                    </div>
                    <div className={s['calculator-card']}>
                        <p className={s['primary-text-sm']}>{t('delivery-calculator')}</p>
                        <p className={s['tab-text']}>{t('china')}</p>
                        <span className={s['calc-form-text']}>{t('your-city')}</span>
                        <Select
                            style={{
                                width: '100%',
                                height: '2.5rem',
                                marginBottom: '12px',
                            }}
                            onChange={(value) => handleSelectCity(value)}
                            defaultValue={selectedCityId}
                            size={'large'}
                            className={s['select']}
                        >
                            {cities?.map((item) => (
                                <Option value={item.id} key={item.id}>
                                    {lang === 'ch' ? item.name_ch : item.name}
                                </Option>
                            ))}
                        </Select>
                        <span className={s['calc-form-text']}>{t('parcel-weight')}</span>
                        <div className={s['weight-wrap']}>
                            <div className={s['row-wrap2']}>
                                <img src={WeightIcon} alt="" />
                                <span
                                    className={s['weight-text']}
                                >{`${selectedWeight} грамм`}</span>
                            </div>
                            <input
                                type="range"
                                min={minWeight}
                                max={maxWeight}
                                className={s['calculator-slider']}
                                onChange={handleSliderChange}
                                style={getBackgroundColor()}
                            />
                        </div>
                        <span className={s['calc-form-text']}>{t('box-price')}</span>
                        <div className={s['weight-wrap']}>
                            <span className={s['box-price']}>$ 0.2</span>
                        </div>
                        <div className={s['line']} />
                        <div className={s['grid-wrap']}>
                            <div className={s['with-right-line']}>
                                <p className={s['calc-form-text']}>{t('total')}:</p>
                                <p className={s['primary-text-lg']}>{totalSum.toFixed(2)} тг</p>
                            </div>
                            <div>
                                <p className={s['calc-form-text']}>{t('tariffForKg')}:</p>
                                <p className={s['primary-text-lg']}>${priceForCity}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={s['banner']}>
                    <p className={s['banner-text']}>{t('enter-track-number')}</p>
                    <span className={s['banner-tab']}>{t('china')}</span>
                    <div className={s['input']}>
                        <input
                            value={trackCode}
                            onChange={(e) => setTrackCode(e.target.value)}
                            className={s['input-style']}
                            placeholder={t('search-placeholder')}
                        />
                        <button className={s['search-button']} onClick={handleSearchClick}>
                            {t('search')}
                        </button>
                    </div>
                </div>

                <div className={s['colorfull-card-grid']}>
                    <div className={s['colorfull-card1']}>
                        <div>
                            <p className={s['primary-text-xl']}>{t('fast-delivery')}</p>
                            <p className={s['hint-text2']}>{t('info-text1')}</p>
                        </div>
                        <span className={s['indicator-text']}>6+</span>
                    </div>
                    <div className={s['colorfull-card1']}>
                        <div>
                            <p className={s['primary-text-xl']}>{t('profitable-commissions')}</p>
                            <p className={s['hint-text2']}>{t('info-text2')}</p>
                        </div>
                        <span className={s['indicator-text']}>100%</span>
                    </div>
                    <div className={s['colorfull-card1']}>
                        <div>
                            <p className={s['primary-text-xl']}>{t('garant')}</p>
                            <p className={s['hint-text2']}>{t('info-text3')}</p>
                        </div>
                        <span className={s['indicator-text']}>{t('money-dollar')}</span>
                    </div>
                </div>

                <h1 className={s['header-text2']}>{t('our-advantages')}</h1>
                <div className={s['colorfull-card-grid']}>
                    <div className={s['colorfull-card-target']}>
                        <p className={s['primary-text-md']}>{t('info-text4')}</p>
                        <p className={s['color-text']}>{t('info-text5')}</p>
                        <img className={s['colorfull-card-img']} src={TargetImg} alt="" />
                    </div>
                    <div className={s['colorfull-card-target']}>
                        <p className={s['primary-text-md']}>{t('fast-and-furious')}</p>
                        <p className={s['color-text']}>{t('info-text6')}</p>
                        <img className={s['colorfull-card-img-clock']} src={ClockImg} alt="" />
                    </div>
                    <div className={s['column-wrap']}>
                        <div className={s['colorfull-card']}>
                            <p className={s['primary-text-md']}>{t('info-text7')}</p>
                            <p className={s['color-text']}>{t('info-text8')}</p>
                        </div>
                        <div className={s['colorfull-card']}>
                            <p className={s['primary-text-md']}>{t('info-text9')}</p>
                            <p className={s['color-text']}>{t('info-text10')}</p>
                        </div>
                        <div className={s['colorfull-card']}>
                            <p className={s['primary-text-md']}>{t('info-text11')}</p>
                            <p className={s['color-text']}>{t('info-text12')}</p>
                        </div>
                    </div>
                </div>

                <div className={s['feedback-wrap']}>
                    <h1 className={s['header-text2']}>{t('feedback-header')}</h1>
                    <div className={s['feedback-content']}>
                        <Carousel autoplay arrows>
                            {feedbackList.map((feedbackGroup) => (
                                <div>
                                    <div className={s['carousel-card-grid']}>
                                        {feedbackGroup.map((feedback) => (
                                            <div className={s['feedback-item']}>
                                                <div className={s['row-wrap2']}>
                                                    <img src={StarIcon} alt="" />
                                                    <img src={StarIcon} alt="" />
                                                    <img src={StarIcon} alt="" />
                                                    <img src={StarIcon} alt="" />
                                                    <img src={StarIcon} alt="" />
                                                </div>
                                                <p className={s['feedback-name']}>
                                                    {t(feedback.name)}
                                                </p>
                                                <p className={s['feedback-city']}>Алматы</p>
                                                <p className={s['feedback-text']}>
                                                    {t(feedback.text)}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>

                {!isMobile && (
                    <>
                        <h1 className={s['header-text3']}>{t('download-our-app')}</h1>
                        <div className={s['grid-wrap2']}>
                            <div className={s['column-wrap3']}>
                                <div className={s['row-wrap4']}>
                                    <span className={s['qr-text-number']}>1</span>
                                    <div>
                                        <p className={s['qr-text-step']}>
                                            {t('download-step1-header')}
                                        </p>
                                        <p className={s['qr-text-hint']}>
                                            {t('download-step1-hint')}
                                        </p>
                                    </div>
                                </div>
                                <div className={s['row-wrap4']}>
                                    <span className={s['qr-text-number']}>2</span>
                                    <div>
                                        <p className={s['qr-text-step']}>
                                            {t('download-step2-header')}
                                        </p>
                                        <p className={s['qr-text-hint']}>
                                            {t('download-step2-hint')}
                                        </p>
                                    </div>
                                </div>
                                <div className={s['row-wrap4']}>
                                    <span className={s['qr-text-number']}>3</span>
                                    <div>
                                        <p className={s['qr-text-step']}>
                                            {t('download-step3-header')}
                                        </p>
                                        <p className={s['qr-text-hint']}>
                                            {t('download-step3-hint')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={s['qr-div']}>
                                <img src={qr} alt="qr" className={s['qr-style']} />
                            </div>
                        </div>
                    </>
                )}

                <div className={s['footer-card']}>
                    <h3 className={s['header-text']} style={{ fontSize: '30px', fontWeight: 500 }}>
                        {t('contactUs')}
                    </h3>
                    <p className={s['header-text']} style={{ fontSize: '14px', fontWeight: 400 }}>
                        {t('contactUsHint')}
                    </p>
                    <span
                        className={s['weight-text']}
                        style={{ opacity: '50%', marginBottom: '-12px' }}
                    >
                        {t('callUS')}:
                    </span>
                    <div className={s['row-wrap']}>
                        <span
                            className={s['header-text']}
                            style={{ fontSize: '22px', fontWeight: 500 }}
                        >
                            <a
                                href="tel:+77070491088"
                                style={{
                                    textDecoration: 'none',
                                    color: 'inherit',
                                }}
                            >
                                +7 (707) 049-10-88
                            </a>
                        </span>
                        <a
                            className={s['secondary-button2']}
                            href="https://wa.me/77070491088"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={WhatsAppIconBlue} alt="" />
                            <span>{t('whatsApp')}</span>
                        </a>
                    </div>
                    <div className={s['row-wrap']}>
                        <Link to={'/user-agreement'}>Пользоват. соглашение</Link>
                        <Link to={'/public-contract-offer'}>Публичный договор (оферта)</Link>
                        <Link to={'/privacy-aggreement'}>Политика конфиденциальности</Link>
                    </div>
                </div>
            </div>
            <CustomModal visible={isSearchModalOpen}>
                <div className={s['modal-container']}>
                    <h1 className={s['modal-header']}>{t('parcelInfo')}</h1>
                    <div className={s['modal-content']}>
                        <p className={s['modal-text']}>{t('orderHistory')}</p>
                        {trackData.map((item) => (
                            <div key={item.update_date} className={s['modal-row']}>
                                <div className={s['modal-date-wrap']}>
                                    <span className={s['date']}>
                                        {format(parseISO(item.update_date), 'd MMMM yyyy', {
                                            locale: ru,
                                        })}
                                    </span>
                                    <span className={s['time']}>
                                        {format(item.update_date, 'HH:mm:ss')}
                                    </span>
                                </div>
                                <span
                                    style={StatusColor(item.status_from.name_ru)}
                                    className={s['status']}
                                >
                                    {item.status_to[language === 'ru' ? 'name_ru' : 'name_kz']}
                                    {'->'}
                                    {item.status_from[language === 'ru' ? 'name_ru' : 'name_kz']}
                                </span>
                            </div>
                        ))}
                    </div>
                    <button
                        className={s['secondary-button3']}
                        onClick={() => setIsSearchModalOpen(false)}
                    >
                        {t('close')}
                    </button>
                </div>
            </CustomModal>
        </div>
    );
};
export default LandingPage;
