import { Input, notification, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import './style.css';
import { forgotPassword } from '../../../api/auth/auth';
import { ReactComponent as Logo } from '../../../assets/check.svg';

const ForgotPasswordForm = ({ translation }) => {
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const [step, setStep] = useState(1);
    const [code, setCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [client, setClient] = useState('');
    const data = {
        username: '',
        phone: '',
        password: '',
        smsCode: '',
        cityId: 0,
        filialId: 0,
        address: '',
        house: '',
    };

    const showErrorNotification = (placement = 'topRight') => {
        api.error({
            message: 'Произошла ошибка, попробуйте позже',
            duration: 1,
            placement,
            style: {
                marginTop: 200,
            },
        });
    };
    const handlePhoneNumberChange = (e) => {
        let value = e.target.value.replace(/\D/g, '');
        if (value.startsWith('7')) {
            value = value.substring(1);
        }
        setPhoneNumber(value);
    };
    const handleContinueClick = () => {
        if (step === 1) {
            forgotPassword({ ...data, phone: `7${phoneNumber.toString()}` })
                .then((res) => {
                    if (res.first === 'smsCode отправлен') {
                        setStep(step + 1);
                        return;
                    }
                    if (res.first === 'Клиент отсутсвует') {
                        setClient('Клиент отсутсвует');
                        setTimeout(() => {
                            setClient('');
                        }, 2000);
                    }
                })
                .catch(() => showErrorNotification());
        } else if (step === 2) {
            forgotPassword({
                ...data,
                phone: `7${phoneNumber.toString()}`,
                smsCode: code.toString(),
            })
                .then((res) => {
                    if (res.first === 'Смс код неверный') {
                        setErrorMessage('Смс код неверный');
                        return;
                    } else {
                        setStep(step + 1);
                    }
                })
                .catch(() => showErrorNotification());
        } else if (step === 3) {
            if (password !== repeatPassword) {
                setErrorMessage('Пароли не совпадают');
                return;
            }
            forgotPassword({
                ...data,
                phone: `7${phoneNumber.toString()}`,
                smsCode: code.toString(),
                password,
            })
                .then(() => navigate('/'))
                .catch(() => showErrorNotification());
        }
    };
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                handleContinueClick();
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [step, phoneNumber, code, password, repeatPassword]);

    const isButtonDisabled =
        (step === 1 && phoneNumber.toString().length !== 10) ||
        (step === 2 && code.toString().length < 4);
    return (
        <>
            {contextHolder}
            {step === 1 && (
                <>
                    <p className={'title-text'}>{translation('enterPhoneNumber')}</p>
                    <p className={'tel-style'}>Телефон</p>
                    {/*<InputNumber*/}
                    {/*    prefix="+7"*/}
                    {/*    className={'register-input'}*/}
                    {/*    type="tel"*/}
                    {/*    value={phoneNumber}*/}
                    {/*    onChange={setPhoneNumber}*/}
                    {/*/>*/}
                    <input
                        className={'register-input'}
                        type="tel"
                        value={`+7${phoneNumber}`}
                        onChange={handlePhoneNumberChange}
                        placeholder="+7 (XXX) XXX-XXXX"
                    />
                </>
            )}
            {step === 2 && (
                <>
                    <Typography.Title
                        style={{
                            color: 'white',
                            fontWeight: '400',
                            textAlign: 'center',
                        }}
                        level={3}
                    >
                        {translation('enterSms')}
                    </Typography.Title>
                    <p className={'number-style'}>
                        {translation('smsSendYou')} +7{phoneNumber}
                    </p>
                    <p className={'tel-style'}>Смс код</p>
                    <input
                        className={'register-input'}
                        type="number"
                        placeholder="Код"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                    />
                </>
            )}
            {step === 3 && (
                <>
                    <Typography.Title
                        style={{
                            color: 'white',
                            fontWeight: '400',
                            textAlign: 'center',
                        }}
                        level={3}
                    >
                        {translation('createNewPassword')}
                    </Typography.Title>
                    <div style={{ marginBottom: '1rem' }}>
                        <Typography.Paragraph
                            style={{
                                color: 'white',
                                fontWeight: '400',
                                marginBottom: 0,
                                marginTop: '0.5rem',
                            }}
                        >
                            {translation('writePasswordSen')}
                        </Typography.Paragraph>
                        <Input
                            className={'register-input'}
                            placeholder={translation('writePasswordSen')}
                            type="password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                setErrorMessage('');
                            }}
                        />
                        <Typography.Paragraph
                            style={{
                                fontSize: '12px',
                                color: 'white',
                                fontWeight: '400',
                                marginBottom: '0.5rem',
                                lineHeight: '16px',
                            }}
                        >
                            Пароль должен содержать более 2 символов
                        </Typography.Paragraph>
                        <Typography.Paragraph
                            style={{
                                color: 'white',
                                fontWeight: '400',
                                marginBottom: 0,
                                marginTop: '0.5rem',
                            }}
                        >
                            Повторите пароль
                        </Typography.Paragraph>
                        <Input
                            className={'register-input'}
                            placeholder="Повторите пароль"
                            type="password"
                            value={repeatPassword}
                            onChange={(e) => {
                                setRepeatPassword(e.target.value);
                                setErrorMessage('');
                            }}
                        />
                        {errorMessage && (
                            <Typography.Paragraph
                                style={{
                                    color: 'red',
                                    fontWeight: '400',
                                    marginBottom: '0.5rem',
                                    marginTop: '0.5rem',
                                    fontSize: '14px',
                                }}
                            >
                                {errorMessage}
                            </Typography.Paragraph>
                        )}
                    </div>
                </>
            )}
            {client && <div className="client-error">{client}</div>}
            <div className={'logo-div'}>
                <Logo width={'100%'} height={'100%'} />
            </div>
            <div
                className={'login-button-group'}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    paddingBottom: '24px',
                    height: '30%',
                }}
            >
                <button
                    className={'btn-style'}
                    disabled={isButtonDisabled}
                    onClick={handleContinueClick}
                >
                    {translation('continue')}
                </button>
            </div>
        </>
    );
};
export default ForgotPasswordForm;
