import React, { useContext, useState } from 'react';
import { useForm } from 'antd/es/form/Form';
import { Button, Form, Input, message, Typography } from 'antd';
import { UserInfoContext } from '../index';
import s from '../styles.module.css';
import { ReactComponent as Logo } from '../../../../assets/check.svg';

export default function PasswordStep({ setIsNextStep }) {
    const [form] = useForm();
    const context = useContext(UserInfoContext);
    const [passwordError, setPasswordError] = useState('');

    const onNext = () => {
        const password = form.getFieldValue('password');
        const confirmPassword = form.getFieldValue('confirmPassword');
        const number = context.user.phone;
        if (password.length < 3) {
            setPasswordError('Пароль должен быть длиннее 2 символов');
            return;
        }
        if (password === confirmPassword) {
            setPasswordError('');
            context.setUser({
                username: '',
                phone: number,
                password,
                smsCode: '',
                address: '',
                cityId: '',
                filialId: '',
                house: '',
            });
            setIsNextStep('city');
        } else {
            setPasswordError('Пароли не совпадают!');
            message.error('Пароли не совпадают!');
        }
    };

    return (
        <>
            <Typography.Title style={{ color: 'white', fontWeight: '400' }} level={3}>
                Придумайте пароль
            </Typography.Title>
            <Form style={{ color: 'white' }} layout="vertical" form={form}>
                <Form.Item
                    label={<Typography.Text style={{ color: 'white' }}>Пароль</Typography.Text>}
                    rules={[{ min: 2, message: 'Введите минимум 3 символа' }]}
                    style={{ marginBottom: '12px' }}
                    name="password"
                >
                    <Input
                        style={{
                            height: '52px',
                            fontFamily: "'Roboto', sans-serif",
                            fontSize: '16px',
                            fontWeight: 400,
                            paddingLeft: '26px',
                            lineHeight: '24px',
                            textAlign: 'left',
                            borderRadius: '8px',
                        }}
                        type={'password'}
                        placeholder="Пароль"
                        className={s['register-input']}
                    />
                </Form.Item>
                {passwordError && (
                    <Typography.Text style={{ color: 'white', fontSize: '12px' }}>
                        {passwordError}
                    </Typography.Text>
                )}
                <Form.Item
                    label={
                        <Typography.Text style={{ color: 'white' }}>
                            Подтвердите пароль
                        </Typography.Text>
                    }
                    rules={[{ min: 4, message: 'Введите минимум 3 символа ' }]}
                    name="confirmPassword"
                >
                    <Input
                        style={{
                            height: '52px',
                            fontFamily: "'Roboto', sans-serif",
                            fontSize: '16px',
                            fontWeight: 400,
                            paddingLeft: '26px',
                            lineHeight: '24px',
                            textAlign: 'left',
                            borderRadius: '8px',
                        }}
                        type={'password'}
                        placeholder="Подтвердите пароль"
                        className={s['register-input']}
                    />
                </Form.Item>
                <div className={'logo-div'}>
                    <Logo width={'100%'} height={'100%'} />
                </div>
                <Button
                    style={{
                        width: '100%',
                        height: '52px',
                        backgroundColor: 'white',
                    }}
                    onClick={onNext}
                >
                    <Typography.Text style={{ color: '#100E71', fontWeight: '500' }}>
                        Продолжить
                    </Typography.Text>
                </Button>
            </Form>
        </>
    );
}
