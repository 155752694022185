import { Button, Form, Input, Typography } from 'antd';
import './style.css';
import { useForm } from 'antd/es/form/Form';
import { useLoginUser } from '../../../api/auth/auth-hooks';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import Spinner from '../../UI/Spinner/Spinner';
import Cookies from 'js-cookie';
import { ReactComponent as Logo } from '../../../assets/check.svg';

const LoginForm = ({
    setContentType,
    isModerator,
    translation,
    handleLanguageChange,
    language,
    handleForgotPasswordClick,
}) => {
    const [form] = useForm();
    const { mutate: login, data, isSuccess, isError, isLoading } = useLoginUser();
    const navigate = useNavigate();
    const [passwordError, setPasswordError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        form.setFieldsValue({ phoneNumber: '+7' });
    }, [form]);

    const onSubmit = () => {
        const password = form.getFieldValue('password');
        let phoneNumber = form.getFieldValue('phoneNumber');

        if (password.length < 3) {
            setPasswordError('Пароль должен быть длиннее 2 символов');
            return;
        }

        if (phoneNumber.length !== 12) {
            setPhoneError('Неверный номер телефона');
            return;
        }
        phoneNumber = phoneNumber.slice(1);

        login({
            password,
            phoneNumber,
            clientType: isModerator ? 'admin' : 'client',
        });
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        if (value.length === 0 || value.length >= 4) {
            setPasswordError('');
        }
    };

    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;

        if (!value.startsWith('+7')) {
            form.setFieldsValue({ phoneNumber: '+7' });
            return;
        }

        const numericValue = value.replace(/[^+\d]/g, '');

        const formattedValue = numericValue.length <= 12 ? numericValue : numericValue.slice(0, 12);

        form.setFieldsValue({ phoneNumber: formattedValue });

        if (formattedValue.length <= 12) {
            setPhoneError('');
        }
    };

    useEffect(() => {
        if (isSuccess && data?.token) {
            const token = data.token;
            localStorage.setItem('token', token);
            setIsAuthenticated(true);
        }
    }, [isSuccess, data]);

    useEffect(() => {
        if (isAuthenticated) {
            const token = Cookies.get('token');
            const decodedToken = jwtDecode(token);
            if (decodedToken.role === 'RoleAdmin' || decodedToken.role === 'RoleLeadAdmin') {
                navigate('/admin-main');
            } else if (decodedToken.role === 'RoleClient') {
                navigate('/client-main');
            }
        }
    }, [isAuthenticated, navigate]);

    useEffect(() => {
        if (isError) {
            setPasswordError('Ошибка авторизации');
        } else if (isLoading) {
            return (
                <div>
                    <Spinner />
                </div>
            );
        }
    }, [isError, isLoading]);

    return (
        <>
            <p className={'title-text'}>{translation('Welcome')}</p>
            <Form style={{ color: 'white' }} layout="vertical" form={form}>
                <Form.Item
                    // label={
                    //     <Typography.Text style={{ color: 'white' }}>
                    //         {translation('phone')}
                    //     </Typography.Text>
                    // }
                    style={{ marginBottom: '16px', marginTop: '40px' }}
                    name="phoneNumber"
                >
                    <Input
                        type={'tel'}
                        placeholder="+7"
                        className="register-input"
                        onChange={handlePhoneNumberChange}
                    />
                </Form.Item>
                {phoneError && (
                    <Typography.Text style={{ color: 'white', fontSize: '12px' }}>
                        {phoneError}
                    </Typography.Text>
                )}
                <Form.Item name="password">
                    <Input
                        placeholder={`${translation('password')}`}
                        type="password"
                        className="register-input"
                        onChange={handlePasswordChange}
                    />
                </Form.Item>
                {passwordError && (
                    <Typography.Text style={{ color: 'white', fontSize: '12px' }}>
                        {passwordError}
                    </Typography.Text>
                )}
                <p className={'forgetPassword'} onClick={handleForgotPasswordClick}>
                    {translation('forgotPassword')}
                </p>
                <div className={'logo-div'}>
                    {/*<img className={'mapLogo'} src={mapLogo} alt={mapLogo} />*/}
                    <Logo width={'100%'} height={'100%'} />
                </div>
                <div className={'login-button-group'}>
                    <Button className={'btn-style'} onClick={onSubmit}>
                        <p className={'btn-text'}>{translation('enter')}</p>
                    </Button>
                    {!isModerator && (
                        <Button
                            className={'btn-style-reg'}
                            onClick={() => setContentType('register')}
                        >
                            <p className={'btn-text'}>{translation('authorized')}</p>
                        </Button>
                    )}
                </div>
            </Form>
        </>
    );
};
export default LoginForm;
