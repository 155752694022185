import s from '../../pages/info-page/styles.module.css';

export function ChinaIconPlane({ styleSVG }) {
    return (
        <svg className={s[`${styleSVG}`]} viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1128_6376)">
                <path d="M24.5 18.5628C24.5 19.2972 24.219 20.0016 23.719 20.5209C23.2189 21.0403 22.5406 21.332 21.8333 21.332H3.16667C2.45942 21.332 1.78115 21.0403 1.28105 20.5209C0.780952 20.0016 0.5 19.2972 0.5 18.5628V6.10126C0.5 5.36682 0.780952 4.66245 1.28105 4.14312C1.78115 3.62379 2.45942 3.33203 3.16667 3.33203H21.8333C22.5406 3.33203 23.2189 3.62379 23.719 4.14312C24.219 4.66245 24.5 5.36682 24.5 6.10126V18.5628Z" />
                <path
                    d="M7.92473 5.98375L8.4154 6.22108L8.80806 5.84375L8.73406 6.38375L9.21406 6.64108L8.67806 6.73708L8.58206 7.27308L8.32473 6.79308L7.78473 6.86708L8.16206 6.47442L7.92473 5.98375ZM11.0347 7.94442L10.7974 8.43442L11.1747 8.82775L10.6354 8.75308L10.3781 9.23375L10.2821 8.69708L9.7454 8.60108L10.2261 8.34375L10.1514 7.80442L10.5447 8.18175L11.0347 7.94442ZM10.3967 10.4637L10.5754 10.9784L11.1201 10.9897L10.6861 11.3184L10.8441 11.8404L10.3967 11.5291L9.9494 11.8404L10.1067 11.3184L9.67273 10.9897L10.2174 10.9784L10.3967 10.4637ZM7.92473 12.6504L8.4154 12.8878L8.80806 12.5104L8.73406 13.0504L9.21406 13.3077L8.67806 13.4038L8.58206 13.9397L8.32473 13.4598L7.78473 13.5338L8.16206 13.1411L7.92473 12.6504ZM5.1674 7.29975L5.78673 9.08042L7.67073 9.11908L6.16873 10.2577L6.71473 12.0617L5.1674 10.9851L3.62006 12.0617L4.16606 10.2577L2.66406 9.11908L4.54806 9.08042L5.1674 7.29975Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_1128_6376">
                    <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
}
