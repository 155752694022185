import React, { useEffect, useReducer } from 'react';
import { isIOS, isIOS as deviceIsIOS, isMobile as deviceIsMobile } from 'react-device-detect';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import LayoutW from './pages/layout/layout';
import UserList from './pages/user-list/user-list';
import LostItems from './pages/lost-items/lost-items';
import UserOrders from './pages/user-orders/user-orders';
import MyProfilePage from './pages/my-profile-page';
import AdminMainPage from './pages/admin-main-page';
import AdminBranchPage from './pages/admin-branches';
import AdminTariffsPage from './pages/admin-tariffs';
import TarifPage from './pages/tarif-page/tarif-page';
import CargoModerator from './pages/cargo-moderator/cargo-moderator';
import './App.css';
import { jwtDecode } from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { useLocalstorage } from './hooks/use-localstorage';
import i18n from './i18n';
import LandingPage from './pages/landing-page';
import OrderTurkeyInfo from './pages/order-turkey-info/order-turkey-info';
import CargoTurkey from './pages/cargo-turkey/cargo-turkey';
import Spinner from './pages/UI/Spinner/Spinner';
import UserOrdersTurkey from './pages/user-orders-turkey/user-orders-turkey';
import Cookies from 'js-cookie';
import OrderChinaInfo from './pages/order-china-info/order-china-info';
import UserAgreement from './pages/documents/user-agrement';
import PublicContactOffer from './pages/documents/public-contact-offer';
import ClientMainPage from './pages/client-main-page';
import ClientAggreementPage from './pages/client-main-page/ClientAggreementPage';
import ClientForbiddenPage from './pages/client-main-page/ClientForbiddenPage';
import RegisterPage from './pages/register-page';
import ReportPage from './pages/report-page/ReportPage';
import DeletedCargoPage from './pages/deleted-cargo';
import DeletedUsersPage from './pages/deleted-users';
import ClientStatusesInfoPage from './pages/client-main-page/ClientStatusesInfoPage';
import { axiosInstances } from './api/axios-instances';
import PrivacyAgreement from './pages/documents/private-contanct-offer';
import CreateBuyer from './pages/createBuyer/createBuyer';

const initialState = {
    roleId: null,
    role: null,
    loading: true,
    isMobile: true,
    isIOS: true,
    isPushEnabled: false,
    language: 'kz',
    isPushToken: '',
    fullURL: '',
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_STATE':
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

const App = () => {
    const location = useLocation();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { t } = useTranslation();
    const [language, setLanguage] = useLocalstorage('language', state.language);

    const updateState = (newState) => {
        dispatch({ type: 'SET_STATE', payload: newState });
    };

    const handleLanguageChange = () => {
        const newLanguage = language === 'kz' ? 'ru' : 'kz';
        i18n.changeLanguage(newLanguage);
        setLanguage(newLanguage);
        updateState({ language: newLanguage });
    };

    useEffect(() => {
        if (language) {
            i18n.changeLanguage(language);
        }
    }, [language]);

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
        updateState({ language: lang });
    };
    const saveToken = async (token, customerId, platform) => {
        const response = await axiosInstances.post(
            `/api/notifications/tokens/save`,
            {},
            {
                params: {
                    token,
                    customerId,
                    platform,
                },
            },
        );

        if (response.status !== 200) {
            throw new Error(`Error: ${response.status}`);
        }
        return response.data;
    };

    const activeToken = async (token, customerId, platform) => {
        const response = await axiosInstances.post(
            `/api/notifications/tokens/activate`,
            {},
            {
                params: {
                    token,
                    customerId,
                    platform,
                },
            },
        );

        if (response.status !== 200) {
            throw new Error(`Error: ${response.status}`);
        }
        return response.data;
    };
    const deactiveToken = async (token, customerId) => {
        const response = await axiosInstances.post(
            `/api/notifications/tokens/deactivate`,
            {},
            {
                params: {
                    token,
                    customerId,
                },
            },
        );

        if (response.status !== 200) {
            throw new Error(`Error: ${response.status}`);
        }
        return response.data;
    };
    useEffect(() => {
        const token = Cookies.get('token');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                updateState({ role: decodedToken.role, roleId: decodedToken.id });
                if (decodedToken.role === 'RoleClient') {
                    const clientId = decodedToken.id;
                    sendNameToNative({ clientId });
                }
            } catch (error) {
                console.error('Ошибка декодирования токена', error);
                Cookies.remove('token');
                updateState({ role: '', roleId: null });
            }
        } else {
            updateState({ role: '' });
        }
        updateState({ loading: false });
    }, [location.pathname]);
    function sendNameToNative(data) {
        try {
            if (
                window.webkit &&
                window.webkit.messageHandlers &&
                window.webkit.messageHandlers.callback
            ) {
                window.webkit.messageHandlers.callback.postMessage(data);
            } else {
                console.log('Native handler is not available');
            }
        } catch (err) {
            console.log('Cannot reach native code:', err);
        }
    }

    useEffect(() => {
        const completeURL = `${window.location.protocol}//${window.location.host}${window.location.pathname}${window.location.search}${window.location.hash}`;
        const queryParams = new URLSearchParams(window.location.search);
        const pushTokenParam = queryParams.get('pushToken');
        updateState({
            fullURL: completeURL,
            isPushToken: pushTokenParam,
        });
    }, []);
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const isMobileParam = queryParams.get('isMobile');
        const isIOSParam = queryParams.get('iSIOS') === 'true';
        const isPushEnabledParam = queryParams.get('isPushEnabled') === 'true';
        const pushTokenParam = queryParams.get('pushToken');

        if (isMobileParam !== null) {
            updateState({
                isMobile: isMobileParam === 'true' ? true : deviceIsMobile,
                isIOS: isIOSParam !== null ? isIOSParam : deviceIsIOS,
                isPushEnabled: isPushEnabledParam,
            });
        } else {
            updateState({
                isMobile: deviceIsMobile,
                isIOS: deviceIsIOS,
                isPushEnabled: false,
            });
        }
    }, [location.search]);

    const onRoleUpdated = async () => {
        if (state.role === 'RoleClient') {
            const platform = state.isIOS ? 'ios' : '';
            try {
                await saveToken(state.isPushToken, state.roleId, platform);
                await activeToken(state.isPushToken, state.roleId, platform);
            } catch (error) {
                console.error('Error saving or activating token for RoleClient:', error);
            }
        }
    };
    useEffect(() => {
        if (state.role === 'RoleClient') {
            onRoleUpdated();
        }
    }, [state.role]);
    const stopPushing = async (push, id) => {
        await deactiveToken(push, id);
    };

    if (state.loading) {
        return <Spinner />;
    }
    const renderRoutes = () => {
        switch (state.role) {
            case 'RoleClient':
                return (
                    <>
                        <Route
                            path="/info-china"
                            element={<OrderChinaInfo t={t} language={language} />}
                        />
                        <Route
                            path="/client-main"
                            element={<ClientMainPage t={t} language={language} />}
                        />
                        <Route
                            path="/info-turkey"
                            element={<OrderTurkeyInfo t={t} language={language} />}
                        />
                        <Route
                            path="/my-orders-turkey"
                            element={
                                <UserOrdersTurkey
                                    isIOS={state.isIOS}
                                    isMobile={state.isMobile}
                                    t={t}
                                    language={language}
                                />
                            }
                        />
                        <Route
                            path="/my-orders"
                            element={
                                <UserOrders
                                    isIOS={state.isIOS}
                                    isMobile={state.isMobile}
                                    t={t}
                                    language={language}
                                />
                            }
                        />
                        <Route path="/rates" element={<TarifPage t={t} />} />
                        <Route
                            path="/my-profile"
                            element={
                                <MyProfilePage
                                    stopPushing={stopPushing}
                                    fullURL={state.fullURL}
                                    push={state.isPushToken}
                                    roleID={state.roleId}
                                    t={t}
                                    language={language}
                                    changeToRussian={() => changeLanguage('ru')}
                                    changeToKazakh={() => changeLanguage('kz')}
                                />
                            }
                        />
                        <Route
                            path="/client-main/aggreement"
                            element={<ClientAggreementPage t={t} />}
                        />
                        <Route
                            path="/client-main/forbidden"
                            element={<ClientForbiddenPage t={t} />}
                        />
                        <Route
                            path="/client-main/statuses"
                            element={<ClientStatusesInfoPage t={t} />}
                        />
                        <Route path="*" element={<Navigate to="/client-main" />} />
                        <Route path="/" element={<Navigate to="/client-main" />} />
                    </>
                );
            case 'RoleAdmin':
                return (
                    <>
                        <Route
                            path="/admin-main"
                            element={<AdminMainPage language={language} translation={t} />}
                        />
                        <Route path="/admin" element={<AdminMainPage />} />
                        <Route path="/user-list" element={<UserList />} />
                        <Route
                            path="/my-cargo"
                            element={
                                <CargoModerator
                                    isIOS={state.isIOS}
                                    isMobile={state.isMobile}
                                    translation={t}
                                    language={language}
                                />
                            }
                        />
                        <Route
                            path="/turkey-cargo"
                            element={
                                <CargoTurkey
                                    isIOS={state.isIOS}
                                    isMobile={state.isMobile}
                                    t={t}
                                    language={language}
                                />
                            }
                        />
                        <Route
                            path="/my-customers"
                            element={<UserList translation={t} language={language} />}
                        />
                        <Route
                            path="/lost-items"
                            element={<LostItems t={t} language={language} />}
                        />
                        <Route
                            path="/info-turkey"
                            element={<OrderTurkeyInfo t={t} language={language} />}
                        />
                        <Route path="/branches" element={<AdminBranchPage role={state.role} />} />
                        <Route path="/rates" element={<AdminTariffsPage role={state.role} />} />
                        <Route path="/" element={<Navigate to="/admin-main" />} />
                        <Route path="*" element={<p>Not found This page</p>} />
                        <Route
                            path="/info-china"
                            element={<OrderChinaInfo t={t} language={language} />}
                        />
                        <Route
                            path="/client-main/statuses"
                            element={<ClientStatusesInfoPage t={t} />}
                        />
                    </>
                );
            case 'RoleLeadAdmin':
            case 'RoleAlmaty':
            case 'RoleChina':
                return (
                    <>
                        <Route
                            path="/admin-main"
                            element={<AdminMainPage language={language} translation={t} />}
                        />

                        <Route path="/admin" element={<AdminMainPage />} />
                        <Route path="/user-list" element={<UserList />} />
                        <Route
                            path="/turkey-cargo"
                            element={
                                <CargoTurkey
                                    isIOS={state.isIOS}
                                    isMobile={state.isMobile}
                                    t={t}
                                    language={language}
                                />
                            }
                        />
                        <Route
                            path="/lost-items"
                            element={<LostItems t={t} language={language} />}
                        />
                        <Route
                            path="/info-china"
                            element={<OrderChinaInfo t={t} language={language} />}
                        />
                        <Route
                            path="/info-turkey"
                            element={<OrderTurkeyInfo t={t} language={language} />}
                        />
                        <Route
                            path="/client-main/statuses"
                            element={<ClientStatusesInfoPage t={t} />}
                        />
                        <Route
                            path="/my-cargo"
                            element={
                                <CargoModerator
                                    isIOS={state.isIOS}
                                    isMobile={state.isMobile}
                                    translation={t}
                                    language={language}
                                />
                            }
                        />
                        <Route
                            path="/my-customers"
                            element={<UserList translation={t} language={language} />}
                        />
                        <Route path="/deleted-cargo" element={<DeletedCargoPage />} />
                        <Route path="/deleted-users" element={<DeletedUsersPage />} />
                        <Route path="/branches" element={<AdminBranchPage role={state.role} />} />
                        <Route path="/rates" element={<AdminTariffsPage role={state.role} />} />
                        <Route path="/" element={<Navigate to="/admin-main" />} />
                        <Route path="*" element={<p>Not found This page</p>} />
                        <Route path="/report" element={<ReportPage t={t} language={language} />} />
                        <Route path="/create-buyer" element={<CreateBuyer />} />
                    </>
                );
            case 'RoleUchet':
                return <Route path="/report" element={<ReportPage t={t} language={language} />} />;
            default:
                return <Route path="*" element={<Navigate to="/" />} />;
        }
    };

    return (
        <LayoutW
            changeToRussian={() => changeLanguage('ru')}
            changeToKazakh={() => changeLanguage('kz')}
            handleSelectCh={() => changeLanguage('ch')}
            translation={t}
            handleLenguageChange={handleLanguageChange}
            language={language}
            isMobile={state.isMobile}
            isIOS={state.isIOS}
        >
            <Routes>
                {renderRoutes()}
                <Route
                    path="/"
                    element={<LandingPage isMobile={state.isMobile} lang={language} />}
                />
                <Route path="/user-agreement" element={<UserAgreement />} />
                <Route path="/public-contract-offer" element={<PublicContactOffer />} />
                <Route path="/privacy-aggreement" element={<PrivacyAgreement />} />
                <Route
                    path="/auth"
                    element={
                        <RegisterPage
                            isIOS={isIOS}
                            t={t}
                            handleLanguageChange={handleLanguageChange}
                            language={language}
                            isModerator={false}
                        />
                    }
                />
                <Route
                    path="/auth/moderator"
                    element={
                        <RegisterPage
                            isIOS={isIOS}
                            t={t}
                            handleLanguageChange={handleLanguageChange}
                            language={language}
                            isModerator={true}
                        />
                    }
                />
                <Route
                    path="/register"
                    element={
                        <RegisterPage
                            isIOS={isIOS}
                            t={t}
                            handleLenguageChange={handleLanguageChange}
                            language={language}
                            showRegisterForm
                        />
                    }
                />
                {!state.role && <Route path="/error" element={<h3>Ошибка с вашим аккаунтом</h3>} />}
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </LayoutW>
    );
};

export default App;
