import { Button, Select, Typography } from 'antd';
import useCities from '../../../../api/common/common-hooks';
import React, { useContext, useState } from 'react';
import { UserInfoContext } from '../index';
import { Option } from 'antd/es/mentions';
import { ReactComponent as Logo } from '../../../../assets/check.svg';

export default function CityStep({ setIsNextStep }) {
    const { data } = useCities();
    const [city, setCity] = useState();

    const context = useContext(UserInfoContext);

    const handleChange = (e) => {
        setCity(e);
    };

    const number = context.user.phone;
    const onNext = () => {
        context.setUser({
            username: '',
            phone: number,
            password: context.user.password,
            smsCode: '',
            cityId: city,
            filialId: '',
            address: '',
            house: '',
            fullName: '',
            lastName: '',
        });
        setIsNextStep('filial');
    };

    return (
        <>
            <Typography.Title style={{ color: 'white', fontWeight: '400' }} level={3}>
                Выберите город
            </Typography.Title>
            <Select
                style={{
                    width: '100%',
                    height: '52px',
                    fontFamily: "'Roboto', sans-serif",
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    textAlign: 'left',
                    borderRadius: '8px',
                }}
                onChange={handleChange}
            >
                {data?.map((item) => (
                    <Option value={item.id} key={item.id}>
                        {item.name}
                    </Option>
                ))}
            </Select>
            <div className={'logo-div'}>
                <Logo width={'100%'} height={'100%'} />
            </div>
            <Button
                style={{
                    width: '100%',
                    height: '52px',
                    backgroundColor: 'white',
                }}
                onClick={onNext}
            >
                <Typography.Text style={{ color: '#100E71', fontWeight: '500' }}>
                    Продолжить
                </Typography.Text>
            </Button>
        </>
    );
}
