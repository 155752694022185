import { QueryClient, useMutation } from 'react-query';
import { RedirectIcon } from '../../../icons';
import './style.css';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { axiosInstances } from '../../../api/axios-instances';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import CustomModal from '../../UI/Modal/Modal';
import s from '../../user-orders-turkey/user-orders-turkey-table/styles.module.css';
import { Select } from 'antd';
import { Option } from 'antd/es/mentions';
import { useBuyers } from '../../../api/user-list-api/user-list-api';

const OrderReport = ({ turkeyOrder, data, translation, language, ChinaRefetch, TurkeyRefetch }) => {
    const navigate = useNavigate();
    const queryClient = new QueryClient();
    const { data: buyersData } = useBuyers();
    const [orderCreate, setOrderCreate] = useState({
        name: '',
        track_code: '',
        price: 0,
        weight: '',
        amount: '',
        customer_id: 0,
        city_id: 0,
        filial_id: 0,
    });
    const [successModal, setSuccessModal] = useState(false);
    const [createOpenModal, setCreateOpenModal] = useState(false);
    const [errorCatch, setErrorCatch] = useState(null);
    const [role, setRole] = useState(null);
    const [errors, setErrors] = useState({});
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [openModalTurkeyUse, setOpenModalTurkeyUse] = useState(false);
    const [orderCreateTurkey, setOrderCreateTurkey] = useState({
        name: '',
        track_code: '',
        price: 0,
        weight: 0,
        amount: '',
        customer_id: 0,
        city_id: 0,
        filial_id: 0,
        order_source: '',
        buyer_id: 0,
        shipping_method: 'Avia',
    });
    const [selectedBuyer, setSelectedBuyer] = useState(null);
    const orders = turkeyOrder;
    const orderList = [
        { key: 'new_count', label: 'new' },
        { key: 'china_count', label: 'china' },
        { key: 'on_road_china_count', label: 'on_road_china' },
        { key: 'on_border_count', label: 'on_border' },
        { key: 'sorting_count', label: 'sorting' },
        { key: 'on_road_count', label: 'on_road' },
        { key: 'sub_filial_count', label: 'sub_filial' },
        { key: 'done_count', label: 'done' },
    ];
    const orderListTurkey = [
        {
            key: 'new',
            label: language === 'ru' ? 'Новый' : language === 'kz' ? 'Жаңа' : '待入库',
        },
        {
            key: 'turkey',
            label:
                language === 'ru'
                    ? 'На складе в Турции'
                    : language === 'kz'
                      ? 'Түркиядағы қоймада'
                      : '土耳其仓库',
        },
        {
            key: 'on_road_turkey',
            label:
                language === 'ru'
                    ? 'Заказы в пути из Турции'
                    : language === 'kz'
                      ? 'Түркиядан сапарға тапсырыс'
                      : '从土耳其运输的订单',
        },
        {
            key: 'on_border',
            label:
                language === 'ru'
                    ? data?.rus?.on_border
                    : language === 'kz'
                      ? data?.kaz?.on_border
                      : data?.ch?.on_border,
        },
        // { key: 'almaty_count', label: language === 'ru' ? data?.rus?.almaty : data?.kaz?.almaty },
        {
            key: 'on_road',
            label:
                language === 'ru'
                    ? data?.rus?.on_road
                    : language === 'kz'
                      ? data?.kaz?.on_road
                      : data?.ch?.on_road,
        },
        {
            key: 'sorting',
            label:
                language === 'ru'
                    ? data?.rus?.sorting
                    : language === 'kz'
                      ? data?.kaz?.sorting
                      : data?.ch?.sorting,
        },
        {
            key: 'sub_filial',
            label:
                language === 'ru'
                    ? data?.rus?.sub_filial
                    : language === 'kz'
                      ? 'Бөлімшеде'
                      : data?.ch?.sub_filial,
        },
        {
            key: 'done',
            label:
                language === 'ru'
                    ? data?.rus?.done
                    : language === 'kz'
                      ? data?.kaz?.done
                      : data?.ch?.done,
        },
    ];

    const handleItemClick = (itemKey, itemLabel) => {
        navigate('/my-orders', { state: { status: itemLabel } });
    };
    const handleItemClickTurkey = (itemKey, itemLabel) => {
        navigate('/my-orders-turkey', { state: { status: itemKey } });
    };
    const toChina = () => {
        navigate('/my-orders');
    };
    const toTurkey = () => {
        navigate('/my-orders-turkey');
    };
    useEffect(() => {
        const token = Cookies.get('token');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                setRole(decodedToken.id);
            } catch (error) {
                setRole('');
            }
        } else {
            setRole('');
        }
    }, []);

    const createOrderByUser = useMutation({
        mutationFn: async (user) => {
            const response = await axiosInstances.post(`/amantrans/orders/create`, user, {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}`,
                    'Content-Type': 'application/json',
                },
            });
            if (response.status !== 200) {
                throw new Error(`Error: ${response.status}`);
            }
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['customers']);
            ChinaRefetch();
        },
        onError: (error) => {
            setErrorCatch(error.response.data);
        },
    });
    const createOrderByUserTurkey = useMutation({
        mutationFn: async (user) => {
            const response = await axiosInstances.post(
                `/amantrans/order_turkey/customer/create`,
                user,
                {
                    headers: {
                        Authorization: `Bearer ${Cookies.get('token')}`,
                        'Content-Type': 'application/json',
                    },
                },
            );
            if (response.status !== 200) {
                throw new Error(`Error: ${response.status}`);
            }
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['customers']);
            TurkeyRefetch();
        },
        onError: (error) => {
            console.error('Error updating customer:', error);
        },
    });
    const handleCreateOpenModal = () => {
        setOrderCreate({
            name: '',
            track_code: '',
            price: 0,
            weight: '',
            amount: '',
            customer_id: 0,
            city_id: 0,
            filial_id: 0,
        });
        setCreateOpenModal(true);
    };
    const validateInputsTurkey = () => {
        const newErrors = {};
        if (!orderCreateTurkey.name) newErrors.name = 'Имя пустое';
        // if (!orderCreateTurkey.track_code) newErrors.track_code = 'Трек код пустой';
        // if (!orderCreateTurkey.price || isNaN(orderCreateTurkey.price))
        //     newErrors.price = 'Цена должна быть';
        // if (!orderCreateTurkey.amount || isNaN(orderCreateTurkey.amount))
        //     newErrors.amount = 'Количество должно быть цифрой';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const handleSaveOrder = () => {
        const updateData = {
            name: orderCreate.name,
            track_code: orderCreate.track_code,
            price: parseFloat(orderCreate.price),
            amount: orderCreate.amount,
            customer_id: role,
            shipping_method: orderCreateTurkey.shipping_method,
        };
        createOrderByUser.mutate(updateData, {
            onSuccess: () => {
                setSuccessModal(true);
                setErrorCatch(null);
                setCreateOpenModal(false);
                setOrderCreate({
                    name: '',
                    track_code: '',
                    price: 0,
                    amount: '',
                    customer_id: 0,
                    shipping_method: 'Avia',
                });
            },
            onError: (error) => {
                console.error('Error updating customer:', error);
            },
        });
    };
    const handleInputCreate = (e) => {
        const { name, value } = e.target;
        setOrderCreate((prevState) => ({ ...prevState, [name]: value }));
    };
    const handleCreateCloseModal = () => {
        setErrorCatch(null);
        setCreateOpenModal(false);
        setSuccessModal(false);
        setOrderCreate({
            name: '',
            track_code: '',
            price: 0,
            weight: '',
            amount: '',
            customer_id: 0,
        });
    };
    const handleChangeBuyer_d = (value) => {
        setOrderCreateTurkey((prevState) => ({
            ...prevState,
            buyer_id: value,
        }));
    };
    const handleChangeOrderSource = (value) => {
        setSelectedBuyer(value);
        setOrderCreateTurkey((prevState) => ({
            ...prevState,
            order_source: value,
        }));
    };
    const typeBuyer = ['От Байера', 'От оптовика', 'Само-зарегистрированный'];
    const validateInputs = () => {
        const newErrors = {};
        if (!orderCreate.name) newErrors.name = 'Имя пустое';
        if (!orderCreate.track_code) newErrors.track_code = 'Трек код пустой';
        if (!orderCreate.price || isNaN(orderCreate.price)) newErrors.price = 'Цена должна быть';
        if (!orderCreate.amount || isNaN(orderCreate.amount))
            newErrors.amount = 'Количество должно быть цифрой';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSave = () => {
        if (validateInputs()) {
            handleSaveOrder();
        } else {
            setShowErrorMessage(true);
            setTimeout(() => {
                setErrors({});
                setShowErrorMessage(false);
            }, 2000);
        }
    };
    const openModalTurkey = () => {
        setOpenModalTurkeyUse(true);
    };
    const handleSaveOrderTurkey = () => {
        if (validateInputsTurkey()) {
            const updateData = {
                name: orderCreateTurkey.name,
                track_code: orderCreateTurkey.track_code,
                price: 1,
                weight: orderCreateTurkey.weight,
                amount: 1,
                order_source: orderCreateTurkey.order_source,
                buyer_id: selectedBuyer === 'От Байера' ? orderCreateTurkey.buyer_id : null,
                customer_id: role,
                shipping_method: orderCreateTurkey.shipping_method, // Добавили это поле
            };
            createOrderByUserTurkey.mutate(updateData, {
                onSuccess: () => {
                    setOpenModalTurkeyUse(false);
                    setOrderCreateTurkey({
                        name: '',
                        track_code: '',
                        price: 0,
                        amount: '',
                        customer_id: 0,
                        city_id: 0,
                        filial_id: 0,
                        order_source: '',
                        buyer_id: 0,
                        shipping_method: 'Avia', // Сбрасываем значение по умолчанию
                    });
                    setSuccessModal(true);
                },
                onError: (error) => {
                    console.error('Error updating customer:', error);
                },
            });
        } else {
            setShowErrorMessage(true);
            setTimeout(() => {
                setErrors({});
                setShowErrorMessage(false);
            }, 2000);
        }
    };

    const handleCloseModalTurkey = () => {
        setOpenModalTurkeyUse(false);
    };
    return (
        <div className={'order-report-container'}>
            <CustomModal visible={createOpenModal} onClose={handleCreateCloseModal}>
                <div className="modal-div">
                    <p className="modal-title">{translation('createOrderByClient')}</p>
                    {errorCatch != null ? <p className="modal-error">{errorCatch}</p> : ''}
                    <div className="input-div">
                        <div className="input-container">
                            <input
                                className={`input-modal ${errors.name ? 'input-error' : ''}`}
                                name="name"
                                value={orderCreate.name}
                                onChange={handleInputCreate}
                            />
                            <label className={`floating-label ${orderCreate.name ? 'filled' : ''}`}>
                                {translation('writeOrderName')}
                            </label>
                        </div>
                        <div className="input-container">
                            <input
                                className={`input-modal ${errors.track_code ? 'input-error' : ''}`}
                                name="track_code"
                                value={orderCreate.track_code}
                                onChange={handleInputCreate}
                            />
                            <label
                                className={`floating-label ${orderCreate.track_code ? 'filled' : ''}`}
                            >
                                {translation('writeTrackCode')}
                            </label>
                        </div>
                        <div className="input-container">
                            <input
                                className={`input-modal ${errors.price ? 'input-error' : ''}`}
                                name="price"
                                type="number"
                                value={orderCreate.price || ''}
                                onChange={handleInputCreate}
                            />
                            <label
                                className={`floating-label ${orderCreate.price ? 'filled' : ''}`}
                            >
                                {translation('writePrice')}
                            </label>
                        </div>
                        <div className="input-container">
                            <input
                                className={`input-modal ${errors.amount ? 'input-error' : ''}`}
                                name="amount"
                                type="number"
                                value={orderCreate.amount || ''}
                                onChange={handleInputCreate}
                            />
                            <label
                                className={`floating-label ${orderCreate.amount ? 'filled' : ''}`}
                            >
                                {translation('writeAmount')}
                            </label>
                        </div>
                    </div>
                    {showErrorMessage && (
                        <p className="error-message">Заполните, пожалуйста, все поля.</p>
                    )}
                    <div className="modal-button">
                        <button className="button-modal" onClick={handleCreateCloseModal}>
                            {translation('otmena')}
                        </button>
                        <button className="button-modal" onClick={handleSave}>
                            {translation('save')}
                        </button>
                    </div>
                </div>
            </CustomModal>

            <CustomModal visible={successModal} onClose={handleCreateCloseModal}>
                <div className="done-modal">
                    <p className="userPlus">{translation('succesOderCreate')}</p>
                    <svg
                        width="101"
                        height="101"
                        viewBox="0 0 101 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M50.1357 100.842C43.3057 100.842 36.8678 99.5346 30.822 96.9202C24.8089 94.3058 19.4985 90.6947 14.8906 86.0869C10.2828 81.479 6.67169 76.1686 4.05731 70.1555C1.44293 64.1098 0.135742 57.6719 0.135742 50.8418C0.135742 44.0117 1.44293 37.5902 4.05731 31.5771C6.67169 25.5313 10.2665 20.2045 14.8416 15.5967C19.4495 10.9889 24.7599 7.37774 30.773 4.76337C36.8187 2.14899 43.2567 0.841797 50.0867 0.841797C56.9168 0.841797 63.3547 2.14899 69.4005 4.76337C75.4462 7.37774 80.773 10.9889 85.3808 15.5967C89.9887 20.2045 93.5998 25.5313 96.2142 31.5771C98.8286 37.5902 100.136 44.0117 100.136 50.8418C100.136 57.6719 98.8286 64.1098 96.2142 70.1555C93.5998 76.1686 89.9887 81.479 85.3808 86.0869C80.773 90.6947 75.4462 94.3058 69.4005 96.9202C63.3874 99.5346 56.9658 100.842 50.1357 100.842ZM44.6455 74.7634C45.4952 74.7634 46.2632 74.5673 46.9495 74.1751C47.6357 73.783 48.2403 73.1947 48.7632 72.4104L71.8024 36.0869C72.0965 35.5967 72.3743 35.0738 72.6357 34.5183C72.8972 33.9627 73.0279 33.4072 73.0279 32.8516C73.0279 31.7078 72.6031 30.8091 71.7534 30.1555C70.9037 29.4692 69.956 29.1261 68.9103 29.1261C67.4723 29.1261 66.2959 29.8777 65.3808 31.381L44.4495 65.0085L34.4985 52.1653C33.8776 51.3483 33.273 50.7928 32.6848 50.4987C32.1292 50.2045 31.492 50.0575 30.773 50.0575C29.6619 50.0575 28.7305 50.466 27.9789 51.283C27.2272 52.0673 26.8514 53.015 26.8514 54.1261C26.8514 54.6817 26.9495 55.2372 27.1455 55.7928C27.3743 56.3157 27.6684 56.8222 28.0279 57.3124L40.3318 72.4104C40.9854 73.2601 41.6553 73.8647 42.3416 74.2242C43.0279 74.5836 43.7959 74.7634 44.6455 74.7634Z"
                            fill="white"
                        />
                    </svg>
                    <button className="button-done" onClick={handleCreateCloseModal}>
                        {translation('continue')}
                    </button>
                </div>
            </CustomModal>
            <CustomModal visible={openModalTurkeyUse} onClose={handleCloseModalTurkey}>
                <div className={s['form']}>
                    <p className={s['modal-title']}>{translation('createOrderByClient')}</p>
                    <div className={s['input-container']}>
                        <input
                            value={orderCreateTurkey.name}
                            style={{ padding: '5px, 10px' }}
                            className={`${s['input-modal']} ${errors.name ? s['input-error'] : ''}`}
                            onChange={(e) =>
                                setOrderCreateTurkey((prevState) => ({
                                    ...prevState,
                                    name: e.target.value,
                                }))
                            }
                        />
                        <label className={s['floating-label']}>{translation('productName')}</label>
                    </div>
                    <div className={s['input-container']}>
                        <input
                            value={orderCreateTurkey.weight}
                            style={{ padding: '5px, 10px' }}
                            className={`${s['input-modal']} ${errors.weight ? s['input-error'] : ''}`}
                            onChange={(e) =>
                                setOrderCreateTurkey((prevState) => ({
                                    ...prevState,
                                    weight: e.target.value,
                                }))
                            }
                        />
                        <label className={s['floating-label']}>{translation('weight')}</label>
                    </div>
                    <div className={s['input-container']}>
                        <Select
                            className={s['input-modal-select']}
                            onChange={handleChangeOrderSource}
                            placeholder={translation('selectSeller')}
                        >
                            {typeBuyer?.map((item) => (
                                <Option value={item} key={item}>
                                    {item}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div className={s['input-container']}>
                        <select
                            className={`${s['input-modal']}`}
                            name="shipping_method"
                            value={orderCreateTurkey.shipping_method}
                            onChange={(e) =>
                                setOrderCreateTurkey((prevState) => ({
                                    ...prevState,
                                    shipping_method: e.target.value,
                                }))
                            }
                        >
                            <option value="Avto">Avto</option>
                            <option value="Avia">Avia</option>
                        </select>
                        <label style={{ top: '10px' }} className={`${s['floating-label']}`}>
                            {translation('type-delivery')}
                        </label>{' '}
                    </div>
                    {selectedBuyer === 'От Байера' && (
                        <div className={s['input-container']}>
                            <Select
                                className={s['input-modal-select']}
                                onChange={handleChangeBuyer_d}
                                placeholder={translation('selectBuyer')}
                            >
                                {buyersData?.map((item) => (
                                    <Option value={item.id} key={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    )}
                    {showErrorMessage && (
                        <p className={s['error-message']}>Заполните, пожалуйста, все поля.</p>
                    )}
                    <div className={s['modal-button']}>
                        <button className={s['button-modal']} onClick={handleCloseModalTurkey}>
                            {translation('cancel')}
                        </button>
                        <button className={s['button-modal']} onClick={handleSaveOrderTurkey}>
                            {translation('save')}
                        </button>
                    </div>
                </div>
            </CustomModal>

            <CustomModal visible={successModal} onClose={handleCreateCloseModal}>
                <div className={s['done-modal']}>
                    <p className={s['userPlus']}>Заказ создан успешно</p>
                    <svg
                        width="101"
                        height="101"
                        viewBox="0 0 101 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M50.1357 100.842C43.3057 100.842 36.8678 99.5346 30.822 96.9202C24.8089 94.3058 19.4985 90.6947 14.8906 86.0869C10.2828 81.479 6.67169 76.1686 4.05731 70.1555C1.44293 64.1098 0.135742 57.6719 0.135742 50.8418C0.135742 44.0117 1.44293 37.5902 4.05731 31.5771C6.67169 25.5313 10.2665 20.2045 14.8416 15.5967C19.4495 10.9889 24.7599 7.37774 30.773 4.76337C36.8187 2.14899 43.2567 0.841797 50.0867 0.841797C56.9168 0.841797 63.3547 2.14899 69.4005 4.76337C75.4462 7.37774 80.773 10.9889 85.3808 15.5967C89.9887 20.2045 93.5998 25.5313 96.2142 31.5771C98.8286 37.5902 100.136 44.0117 100.136 50.8418C100.136 57.6719 98.8286 64.1098 96.2142 70.1555C93.5998 76.1686 89.9887 81.479 85.3808 86.0869C80.773 90.6947 75.4462 94.3058 69.4005 96.9202C63.3874 99.5346 56.9658 100.842 50.1357 100.842ZM44.6455 74.7634C45.4952 74.7634 46.2632 74.5673 46.9495 74.1751C47.6357 73.783 48.2403 73.1947 48.7632 72.4104L71.8024 36.0869C72.0965 35.5967 72.3743 35.0738 72.6357 34.5183C72.8972 33.9627 73.0279 33.4072 73.0279 32.8516C73.0279 31.7078 72.6031 30.8091 71.7534 30.1555C70.9037 29.4692 69.956 29.1261 68.9103 29.1261C67.4723 29.1261 66.2959 29.8777 65.3808 31.381L44.4495 65.0085L34.4985 52.1653C33.8776 51.3483 33.273 50.7928 32.6848 50.4987C32.1292 50.2045 31.492 50.0575 30.773 50.0575C29.6619 50.0575 28.7305 50.466 27.9789 51.283C27.2272 52.0673 26.8514 53.015 26.8514 54.1261C26.8514 54.6817 26.9495 55.2372 27.1455 55.7928C27.3743 56.3157 27.6684 56.8222 28.0279 57.3124L40.3318 72.4104C40.9854 73.2601 41.6553 73.8647 42.3416 74.2242C43.0279 74.5836 43.7959 74.7634 44.6455 74.7634Z"
                            fill="white"
                        />
                    </svg>
                    <button className={s['button-done']} onClick={handleCreateCloseModal}>
                        Продолжить
                    </button>
                </div>
            </CustomModal>

            <div className="order-report-card">
                <div onClick={toChina} className="order-report-header">
                    <p className="order-report-title">
                        {translation('china')}
                        <RedirectIcon />
                    </p>
                </div>

                <div className="order-report-stats">
                    <p className="order-report-all-count">{data?.all_count?.all}</p>
                    <p className="order-report-secondary-count">
                        {language === 'ru'
                            ? data?.rus?.all
                            : language === 'kz'
                              ? data?.kaz?.all
                              : '所有订单'}
                    </p>
                </div>

                <div className="order-report-grid">
                    {orderList.map((item) => (
                        <div
                            className="order-report-item"
                            key={item.key}
                            onClick={() => handleItemClick(item.key, item.label)}
                            style={{ cursor: 'pointer' }}
                        >
                            <p className="order-report-label">
                                {language === 'ru'
                                    ? item.key === 'on_road_count'
                                        ? 'В пути к филиалу'
                                        : data?.rus?.[item.label]
                                    : language === 'kz'
                                      ? item.key === 'on_road_count'
                                          ? 'Бөлімшеге жолға шықты'
                                          : data?.kaz?.[item.label]
                                      : data?.ch?.[item.label]}
                            </p>
                            <p className="order-report-value">{data?.[item.key]?.[item.label]}</p>
                        </div>
                    ))}
                    <button className={'button-text'} onClick={handleCreateOpenModal}>
                        {translation('createOrder')}
                    </button>
                </div>
            </div>
            <div className="order-report-card">
                <div onClick={toTurkey} className="order-report-header">
                    <p className="order-report-title">
                        {language === 'ru' ? 'Турция' : language === 'kz' ? 'Түркия' : '土耳其'}
                        <RedirectIcon />
                    </p>
                </div>

                <div className="order-report-stats">
                    <p className="order-report-all-count">{orders?.allCount}</p>
                    <p className="order-report-secondary-count">
                        {language === 'ru'
                            ? data?.rus?.all
                            : language === 'kz'
                              ? data?.kaz?.all
                              : '所有订单'}
                    </p>
                </div>

                <div className="order-report-grid">
                    {orderListTurkey.map((item, index) => (
                        <div
                            className="order-report-item"
                            key={index}
                            onClick={() => handleItemClickTurkey(item.key, item.label)} // Add onClick to navigate
                            style={{ cursor: 'pointer' }}
                        >
                            <p className="order-report-label">{item.label}</p>{' '}
                            <p className="order-report-value">{orders?.[item.key]}</p>{' '}
                        </div>
                    ))}
                    <button className={'button-text'} onClick={openModalTurkey}>
                        {translation('createOrder')}
                    </button>
                </div>
            </div>
        </div>
    );
};
export default OrderReport;
