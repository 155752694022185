import ru from './translation/ru.json';
import kz from './translation/kz.json';
import ch from './translation/ch.json';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    ru: {
        translation: ru,
    },
    kz: {
        translation: kz,
    },
    ch: {
        translation: ch,
    },
};
i18next.use(initReactI18next).init({
    resources,
    lng: JSON.parse(localStorage.getItem('language')),
    fallbackLng: 'kz',
    interpolation: {
        escapeValue: false,
    },
});
export default i18next;
