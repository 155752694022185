import s from './styles-info.module.css';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { aggreementData } from './data';

const ClientAggreementPage = ({ t }) => {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div className={s['container']}>
            <p className={s['breadcrumps']} onClick={() => navigate('/client-main')}>
                {t('backToMain')}
            </p>
            <h1 className={s['title']}>{t('aggreement')}</h1>
            <div className={s['table-banner']}>
                <h1 className={s['aggreement-banner-title']}>{t('aggreement-banner-title')}</h1>
                <p className={s['aggreement-banner-text']}>{t('aggreement-banner-text')}</p>
            </div>
            <div className={s['table-outside']}>
                <div className={s['table-columns-wrap']}>
                    <span className={s['table-header']}>{t('aggreementColumn')}</span>
                    <span className={s['table-header']}>{t('explanation')}</span>
                </div>
                <div className={s['table-data']}>
                    {aggreementData.map((row, index) => (
                        <div key={index} className={s['table-columns-wrap']}>
                            <span className={s['table-cell-icon']}>
                                <span className={s['table-cell-number-icon']}>{index + 1}</span>
                            </span>
                            <span className={s['table-cell']}>
                                <div key={index}>
                                    <h1 className={s['table-cell-header']}>{t(row.title)}</h1>
                                    <p>{t(row.subTitle)}</p>
                                    <ul style={{ listStyleType: row.isNumbers ? 'auto' : 'disc' }}>
                                        {row.list?.map((liItem, index) => (
                                            <li className={s['table-cell-li']} key={index}>
                                                {t(liItem)}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </span>
                        </div>
                    ))}
                </div>
            </div>
            <p className={s['breadcrumps']} onClick={() => navigate('/client-main')}>
                {t('backToMain')}
            </p>
        </div>
    );
};
export default ClientAggreementPage;
