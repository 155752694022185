const StatusColor = (status, isClicked) => {
    if (isClicked) {
        return { color: 'white', borderColor: 'white', backgroundColor: 'rgba(16, 14, 113, 1)' };
    }

    switch (status) {
        case 'Новый':
            return {
                color: 'rgba(73, 75, 79, 1)',
                borderColor: 'rgba(73, 75, 79, 1)',
                backgroundColor: 'rgba(234, 236, 240, 1)',
            };
        case 'На выдачу':
        case 'Готов к выдаче':
            return {
                color: 'rgba(16, 14, 113, 1)',
                borderColor: 'rgba(16, 14, 113, 1)',
                backgroundColor: 'rgba(202, 224, 255, 1)',
            };
        case 'Выдан':
        case 'Выдача':
            return {
                color: 'rgba(54, 89, 15, 1)',
                borderColor: 'rgba(54, 89, 15, 1)',
                backgroundColor: 'rgba(226, 251, 197, 1)',
            };
        case 'Все грузы':
        case 'На складе в Китае':
        case 'В пути из Китая':
        case 'В пути по КЗ':
        case 'В пути из Турции':
        case 'В Алматы':
        case 'В городе':
        case 'В пути':
        case 'В филиале':
        case 'На складе':
        case 'Сортировка':
        case 'На границе':
        case 'В дороге':
        case 'На складе в Турции':
        case 'В подфилиале':
            return {
                color: 'rgba(137, 71, 5, 1)',
                backgroundColor: 'rgba(255, 189, 123, 1)',
                borderColor: 'rgba(137, 71, 5, 1)',
            };
        case 'Ошибка оплаты':
        case 'Ваш заказ потерян':
            return {
                color: 'rgba(116, 31, 6, 1)',
                borderColor: 'rgba(116, 31, 6, 1)',
                backgroundColor: 'rgba(251, 132, 132, 1)',
            };
        case 'Отклонен':
        case 'Отменен':
        case 'Отказ':
        case 'Завершен':
            return {
                color: 'rgba(73, 75, 79, 1)',
                borderColor: 'rgba(73, 75, 79, 1)',
                backgroundColor: 'rgba(234, 236, 240, 1)',
            };
        case '':
        default:
            return {};
    }
};

export default StatusColor;
