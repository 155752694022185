// src/components/PublicContractOffer.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PublicContractOffer = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const sections = t('publicAgreement.sections', { returnObjects: true });

    return (
        <div
            style={{
                fontFamily: 'Roboto, sans-serif',
                fontSize: '14px',
                lineHeight: '1.6',
                margin: '0',
                padding: '20px',
                backgroundColor: '#f0f0f0',
                minHeight: '100vh',
            }}
        >
            <div
                style={{
                    maxWidth: '800px',
                    margin: 'auto',
                    backgroundColor: '#fff',
                    padding: '30px',
                    borderRadius: '8px',
                    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                }}
            >
                <h1 style={{ textAlign: 'center' }}>{t('publicAgreement.title')}</h1>
                <h2 style={{ textAlign: 'center', marginBottom: '40px' }}>
                    {t('publicAgreement.subtitle')}
                </h2>

                {Object.entries(sections).map(([chapterKey, chapter]) => (
                    <div key={chapterKey} style={{ marginBottom: '30px' }}>
                        <h3 style={{ borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                            {chapter.title}
                        </h3>
                        <ol style={{ paddingLeft: '20px' }}>
                            {Object.entries(chapter.items).map(([itemKey, itemContent]) => {
                                // Если itemContent содержит маркеры списка, разбиваем на строки
                                const isList =
                                    itemContent.includes('•') || itemContent.includes('');
                                if (isList) {
                                    // Разбиваем на пункты списка по символам
                                    const listItems = itemContent
                                        .split('\n')
                                        .filter((line) => line.trim() !== '');
                                    return (
                                        <li key={itemKey} style={{ marginBottom: '10px' }}>
                                            {listItems.map((line, index) => (
                                                <span key={index}>
                                                    {line.startsWith('•') ||
                                                    line.startsWith('') ? (
                                                        <strong>{line.slice(0, 1)}</strong>
                                                    ) : null}
                                                    {line}
                                                    <br />
                                                </span>
                                            ))}
                                        </li>
                                    );
                                }

                                // Если в пункте есть символы переноса строки, заменяем их на <br/>
                                const formattedText = itemContent.split('\n').map((line, index) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ));

                                return (
                                    <li key={itemKey} style={{ marginBottom: '10px' }}>
                                        {formattedText}
                                    </li>
                                );
                            })}
                        </ol>
                    </div>
                ))}

                <button
                    onClick={() => navigate(-1)}
                    style={{
                        display: 'block',
                        margin: '30px auto 0',
                        padding: '10px 30px',
                        fontSize: '16px',
                        fontFamily: 'Roboto, sans-serif',
                        cursor: 'pointer',
                        backgroundColor: '#007bff',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '4px',
                        transition: 'background-color 0.3s',
                    }}
                    onMouseOver={(e) => (e.target.style.backgroundColor = '#0056b3')}
                    onMouseOut={(e) => (e.target.style.backgroundColor = '#007bff')}
                >
                    Назад
                </button>
            </div>
        </div>
    );
};

export default PublicContractOffer;
