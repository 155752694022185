import React, { useEffect, useState } from 'react';
import s from './styles-info.module.css';
import { useNavigate } from 'react-router-dom';
import { statusesData } from './data';

const ClientStatusesInfoPage = ({ t }) => {
    const navigate = useNavigate();

    const [searchInput, setSearchInput] = useState('');

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div className={s['container']}>
            <p className={s['breadcrumps']} onClick={() => navigate(-1)}>
                {t('backToMain')}
            </p>
            <h1 className={s['title']}>{t('statuses')}</h1>
            <input
                className={s['input-style']}
                placeholder={t('searchInputName')}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
            />
            <div className={s['table-outside']}>
                <div className={s['table-columns-wrap-statuses']}>
                    <span className={s['table-header']}>{t('status')}</span>
                    <span className={s['table-header']}>{t('explanation')}</span>
                </div>
                <div className={s['table-data']}>
                    {statusesData
                        .filter((item) =>
                            t(item.title).toLowerCase().includes(searchInput.toLowerCase()),
                        )
                        .map((row, index) => (
                            <div key={index} className={s['table-row']}>
                                <span className={s['table-cell-status']}>
                                    <span className={s[row.type]}>{t(row.title)}</span>
                                </span>
                                <span className={s['table-cell-text']}>{t(row.text)}</span>
                            </div>
                        ))}
                </div>
            </div>
            <p className={s['breadcrumps']} onClick={() => navigate(-1)}>
                {t('backToMain')}
            </p>
        </div>
    );
};

export default ClientStatusesInfoPage;
