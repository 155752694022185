import { axiosInstances } from '../axios-instances';
import Cookies from 'js-cookie';

class Customers {
    fetchCustomers = async () => {
        const response = await axiosInstances.get('/amantrans/customers');
        if (response.status !== 200) {
            throw new Error(`Error: ${response.status}`);
        }
        return response.data;
    };
}

export const customers = new Customers();

export const getDeletedUsers = async ({ start, end, cityId, number, page, sort }) => {
    const token = Cookies.get('token');
    if (!token) {
        throw new Error('Token is not available in localStorage');
    }
    const params = {
        dateFrom: start,
        dateTo: end,
        cityId: cityId,
        page,
        size: number,
        sort,
    };

    const response = await axiosInstances.get('/amantrans/customers/deleted', {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        params: params,
    });

    if (response.status !== 200) {
        throw new Error(`Error: ${response.status}`);
    }
    return response.data;
};

export const changeUserActiveStatus = async ({ id }) => {
    const token = Cookies.get('token');

    const response = await axiosInstances.put(`/amantrans/customers/is-active-change/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });

    if (response.status !== 200) {
        throw new Error(`Error: ${response.status}`);
    }
    return response.data;
};
