import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Input, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { UserInfoContext } from '../index';
import s from '../styles.module.css';
import useCreateUser from '../../../../api/auth/auth-hooks';
import { ReactComponent as Logo } from '../../../../assets/check.svg';

const FirstStep = ({ setIsNext, handleLanguageChange, language, translation }) => {
    const [form] = useForm();
    const context = useContext(UserInfoContext);
    const { mutate } = useCreateUser(setIsNext);
    const [phoneError, setPhoneError] = useState('');
    const [isBuyer, setIsBuyer] = useState(false);
    useEffect(() => {
        form.setFieldsValue({ phoneNumber: '+7' });
    }, [form]);

    const onNext = () => {
        const phoneNumber = form.getFieldValue('phoneNumber');

        // Проверка корректности номера телефона
        if (phoneNumber.length !== 12) {
            setPhoneError('Неверный номер телефона');
            return;
        }

        const formattedPhoneNumber = phoneNumber.replace('+', '');

        // Установка пользователя в контексте
        context.setUser({
            username: '',
            phone: formattedPhoneNumber,
            password: '',
            smsCode: '',
            cityId: '',
            filialId: '',
            address: '',
            house: '',
        });

        if (!isBuyer) {
            mutate({
                username: '',
                email: '',
                fullName: '',
                lastName: '',
                phone: formattedPhoneNumber,
                password: '',
                cityId: '',
                filialId: '',
                smsCode: '',
                address: '',
                house: '',
            });
        }
        setIsNext(isBuyer ? 'password' : 'smsCode');
    };
    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;

        if (!value.startsWith('+7')) {
            form.setFieldsValue({ phoneNumber: '+7' });
            return;
        }

        const numericValue = value.replace(/[^+\d]/g, '');

        const formattedValue = numericValue.length <= 12 ? numericValue : numericValue.slice(0, 12);

        form.setFieldsValue({ phoneNumber: formattedValue });

        if (formattedValue.length <= 12) {
            setPhoneError('');
        }
    };
    const handleBuyerChange = (e) => {
        setIsBuyer(e.target.checked);
    };
    return (
        <>
            <Typography.Title style={{ color: 'white', fontWeight: '400' }} level={3}>
                {translation('register')}
            </Typography.Title>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                <p style={{ fontFamily: 'Roboto, san-serif', fontSize: '14px', color: 'white' }}>
                    Байер
                </p>
                <input type={'checkbox'} onChange={handleBuyerChange} checked={isBuyer} />
            </div>
            <Form style={{ color: 'white' }} layout="vertical" form={form}>
                <Form.Item
                    label={<Typography.Text style={{ color: 'white' }}>Телефон</Typography.Text>}
                    name="phoneNumber"
                >
                    <Input
                        style={{
                            height: '52px',
                            fontFamily: "'Roboto', sans-serif",
                            fontSize: '16px',
                            fontWeight: 400,
                            paddingLeft: '26px',
                            lineHeight: '24px',
                            textAlign: 'left',
                            borderRadius: '8px',
                        }}
                        type={'tel'}
                        placeholder="+7"
                        className={s['register-input']}
                        onChange={handlePhoneNumberChange}
                    />
                </Form.Item>
                {phoneError && (
                    <Typography.Text style={{ color: 'red', fontSize: '12px' }}>
                        {phoneError}
                    </Typography.Text>
                )}
                <div className={'logo-div'}>
                    <Logo width={'100%'} height={'100%'} />
                </div>
                <Button
                    style={{
                        width: '100%',
                        height: '52px',
                        backgroundColor: 'white',
                    }}
                    onClick={onNext}
                >
                    <Typography.Text style={{ color: '#100E71', fontWeight: '500' }}>
                        {translation('continue')}
                    </Typography.Text>
                </Button>
            </Form>
        </>
    );
};
export default FirstStep;
