export function FaceIcon() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.46447 15.7193C5.40215 14.7817 6.67392 14.2549 8 14.2549H16C17.3261 14.2549 18.5979 14.7817 19.5355 15.7193C20.4732 16.657 21 17.9288 21 19.2549V21.2549C21 21.8072 20.5523 22.2549 20 22.2549C19.4477 22.2549 19 21.8072 19 21.2549V19.2549C19 18.4592 18.6839 17.6962 18.1213 17.1336C17.5587 16.571 16.7956 16.2549 16 16.2549H8C7.20435 16.2549 6.44129 16.571 5.87868 17.1336C5.31607 17.6962 5 18.4592 5 19.2549V21.2549C5 21.8072 4.55228 22.2549 4 22.2549C3.44772 22.2549 3 21.8072 3 21.2549V19.2549C3 17.9288 3.52678 16.657 4.46447 15.7193Z"
                fill="#100E71"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 4.25488C10.3431 4.25488 9 5.59803 9 7.25488C9 8.91174 10.3431 10.2549 12 10.2549C13.6569 10.2549 15 8.91174 15 7.25488C15 5.59803 13.6569 4.25488 12 4.25488ZM7 7.25488C7 4.49346 9.23858 2.25488 12 2.25488C14.7614 2.25488 17 4.49346 17 7.25488C17 10.0163 14.7614 12.2549 12 12.2549C9.23858 12.2549 7 10.0163 7 7.25488Z"
                fill="#100E71"
            />
        </svg>
    );
}
